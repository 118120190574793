import { routeToRegex } from "@/utils/paths";

const CRITICAL_PATHS =
  process.env.NEXT_PUBLIC_CRITICAL_PATHS?.split(",").map((path) =>
    routeToRegex(path.trim())
  ) || [];

/**
 * Check if current page is part of a Critical Workflow.
 */
export function isCriticalWorkflow() {
  if (typeof window === "undefined") {
    return false;
  }
  return CRITICAL_PATHS.some((regex) => regex.test(window.location.pathname));
}
