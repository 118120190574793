import { useRouter } from "next/router";
import { useEffect } from "react";
import { useAuthState } from "./useAuthState";

export function useGuardUrlPermissionOnRouteChange() {
  const { activeUserMedspa, urlPermissions, hasUrlPermission } = useAuthState();
  const newPermissionsEnabledForUser = !!activeUserMedspa?.roleId;

  const router = useRouter();

  useEffect(() => {
    // Skip if user is not loaded
    if (!activeUserMedspa) return;

    // Check permissions on route changes
    const handleRouteChange = (url: string) => {
      if (urlPermissions.length === 0 || !newPermissionsEnabledForUser) {
        // loading guards
        return;
      }

      if (url === "/403") {
        return;
      }

      if (newPermissionsEnabledForUser && !hasUrlPermission(url)) {
        router.push("/403");
      }
    };

    // Check permissions on initial load
    if (urlPermissions.length > 0) {
      handleRouteChange(router.asPath);
    }

    router.events.on("routeChangeStart", handleRouteChange);

    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, [
    newPermissionsEnabledForUser,
    hasUrlPermission,
    urlPermissions,
    router,
    activeUserMedspa,
  ]);
}
