import { Box, Breakpoint, SxProps, Theme } from "@mui/material";
import { ReactNode, RefObject } from "react";
import { GREY } from "@/config/mui/colorPalette";
import ContentContainer from "./contentContainer";

type Props = {
  children: ReactNode;
  maxWidth?: Breakpoint;
  sx?: SxProps<Theme>;
  navComponent?: ReactNode;
  topBarComponent?: ReactNode;
  footerComponent?: ReactNode;
  isFullWidthContent?: boolean;
  additionalBannerComponent?: ReactNode;
  contentParentContainerSx?: SxProps<Theme>;
  scrollParentSx?: SxProps<Theme>;
  scrollWrapperRef?: RefObject<HTMLDivElement | null>;
};

export default function PageContainerWrapper({
  children,
  maxWidth,
  sx = {},
  navComponent,
  topBarComponent,
  footerComponent,
  additionalBannerComponent,
  isFullWidthContent = false,
  contentParentContainerSx = {},
  scrollParentSx = {},
  scrollWrapperRef,
}: Props) {
  return (
    <Box
      sx={{
        minHeight: 1,
        display: "flex",
        flexDirection: "row",
        height: "100dvh",
        bgcolor: GREY[5],
        alignItems: "stretch",
        pt: 0,
        overscrollBehaviorY: "none",
      }}
    >
      {/* Left side */}
      {navComponent}
      {/* Right side */}
      <Box
        sx={{
          flex: 1,
          display: "flex",
          width: "100%",
          flexDirection: "column",
          bgcolor: GREY[5],
          position: "relative",
          ...contentParentContainerSx,
        }}
      >
        {topBarComponent}
        {/* Needed for scrollbar to appear on right side */}
        <Box
          sx={{
            flex: 1,
            overflowY: "auto",
            ...scrollParentSx,
          }}
          ref={scrollWrapperRef}
        >
          {/* Additional banner in the content container */}
          {additionalBannerComponent}

          {!isFullWidthContent ? (
            <ContentContainer
              sx={{
                bgcolor: GREY[5], // needed here to pass to <PageHeader/> components that inherit background color
                ...sx,
              }}
              maxWidth={maxWidth}
            >
              {children}
            </ContentContainer>
          ) : (
            children
          )}
          {footerComponent}
        </Box>
      </Box>
    </Box>
  );
}
