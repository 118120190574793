import { Stack, Typography } from "@mui/material";
import { GREY, PRIMARY, VIOLET } from "@/config/mui/colorPalette";
import { formatCurrency } from "@/utils/formatters";
import { CoinIcon } from "../icons";
import InfoCard from "../infoCard/infoCard";

type SuppliesSavingsCardProps = {
  monthlySavings: number;
  totalSavings: number;
  onClick?: () => void;
};

function SuppliesSavingsCard({
  monthlySavings,
  totalSavings,
  onClick,
}: SuppliesSavingsCardProps) {
  return (
    <InfoCard
      sx={{
        my: 0,
        backgroundColor: GREY[5],
        transition: "200ms",
        ".MuiCardContent-root": { py: "8px !important", px: 1.5 },
        cursor: onClick ? "pointer" : "default",
        ...(onClick && {
          "&:hover": {
            backgroundColor: VIOLET[20],
            borderColor: VIOLET[40],
          },
        }),
      }}
      onClick={onClick}
    >
      <Stack spacing={1.5}>
        <Stack direction="row" spacing={1}>
          <CoinIcon color={PRIMARY} />
          <Typography variant="labelSmall">Supplies Savings</Typography>
        </Stack>

        <Stack direction="row" spacing={2}>
          <Stack direction="column">
            <Typography variant="subtitleDefault">
              {formatCurrency({ amount: monthlySavings })}
            </Typography>
            <Typography variant="paragraphTiny">Saved this month</Typography>
          </Stack>

          <Stack direction="column">
            <Typography variant="subtitleDefault">
              {formatCurrency({ amount: totalSavings })}
            </Typography>
            <Typography variant="paragraphTiny">All-time savings</Typography>
          </Stack>
        </Stack>
      </Stack>
    </InfoCard>
  );
}

export default SuppliesSavingsCard;
