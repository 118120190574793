import {
  Client,
  ConnectionState,
  Conversation,
  Message,
} from "@twilio/conversations";
import { useCallback, useRef } from "react";
import useRecordLongLoading from "@/hooks/messages/useRecordLongLoading";
import { ClientState } from "@/types/messages";
import {
  getSubscribedConversations,
  isErrorConnectionState,
  sortConversations,
} from "@/utils/messages";
import useErrorLogger from "@/utils/useErrorLogger";

type TwilioClientProps = {
  onStateChange: (state: ClientState) => void;
  onConversationsUpdate: (
    updater: Conversation[] | ((prev: Conversation[]) => Conversation[])
  ) => void;
  onMessageAdded: (messageSid: string) => void;
  refreshToken: () => void;
};

export const useTwilioClient = ({
  onStateChange,
  onConversationsUpdate,
  onMessageAdded,
  refreshToken,
}: TwilioClientProps) => {
  const twilioClient = useRef<Client>(null);
  const logError = useErrorLogger();

  const { registerStartLoading, registerFinishLoading } = useRecordLongLoading(
    () =>
      onStateChange({
        state: "loading",
        isLongLoading: true,
        connectionState: "connecting",
      })
  );

  const initClient = useCallback(
    async (token: string) => {
      const client = new Client(token);
      registerStartLoading();

      client.on(
        "connectionStateChanged",
        (connectionState: ConnectionState) => {
          if (isErrorConnectionState(connectionState)) {
            onStateChange({
              state: "error",
              connectionState,
            });
            logError(
              new Error(`Twilio client connection state is ${connectionState}`)
            );
            return;
          }

          onStateChange({
            state: "ready",
            connectionState,
          });
        }
      );

      client.on("initFailed", (event) => {
        registerFinishLoading();
        logError("Twilio client initFailed", null, { error: event.error });
        onStateChange({
          state: "error",
          initFailedError: event.error,
          connectionState: client.connectionState,
        });
      });

      client.on("initialized", async () => {
        try {
          const conversations = await getSubscribedConversations(client);
          sortConversations(conversations);
          onConversationsUpdate(conversations);
          onStateChange({
            state: "ready",
            connectionState: client.connectionState,
          });
        } catch (error) {
          logError(error, "getSubscribedConversations() failed");
          onStateChange({
            state: "error",
            connectionState: client.connectionState,
            customErrorMessage: "Could not load conversations.",
          });
        } finally {
          registerFinishLoading();
        }
      });

      client.on("conversationJoined", (conversation) => {
        onConversationsUpdate(
          (prev) => [...prev, conversation] as Conversation[]
        );
      });

      client.on("conversationLeft", (leftConversation) => {
        onConversationsUpdate((prev) =>
          prev.filter((conv) => conv !== leftConversation)
        );
      });

      client.on("messageAdded", async (message: Message) => {
        try {
          const conversations = await getSubscribedConversations(client);
          if (conversations.length) {
            sortConversations(conversations);
            onConversationsUpdate(conversations);
            onMessageAdded(message.sid);
          }
        } catch (error) {
          logError(
            error,
            'on "messageAdded" getSubscribedConversations() failed'
          );
        }
      });

      client.on("tokenAboutToExpire", refreshToken);
      client.on("tokenExpired", refreshToken);

      twilioClient.current = client;
    },
    [
      onStateChange,
      onConversationsUpdate,
      onMessageAdded,
      refreshToken,
      logError,
      registerStartLoading,
      registerFinishLoading,
    ]
  );

  const shutdown = useCallback(() => {
    if (twilioClient.current) {
      twilioClient.current.removeAllListeners();
      twilioClient.current.shutdown();
      twilioClient.current = null;
    }
  }, []);

  return {
    client: twilioClient.current,
    initClient,
    shutdown,
    updateToken: (token: string) => twilioClient.current?.updateToken(token),
  };
};
