import {
  Box,
  Button,
  Modal,
  Stack,
  SxProps,
  Theme,
  ThemeProvider,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import {
  ConfirmCheckboxOptions,
  OpenConfirmOptions,
} from "@/components/providers/confirmProvider";
import { useSelectiveEffect } from "@/hooks/misc/useSelectiveEffect";
import LoadingButton from "../buttons/loadingButton";
import AdditionalCheckbox from "./additionalCheckbox";

const style = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  textAlign: "center",
  boxShadow: "0px 4px 15px rgba(56, 19, 60, 0.25)",
  py: 3,
  px: 2,
  borderRadius: "16px",
  maxWidth: "400px",
} as const;

type Props = {
  isConfirmOpen: boolean;
  confirm: (checkboxValue?: boolean) => void;
  discard: (checkboxValue?: boolean) => void;
  confirmOptions: OpenConfirmOptions;
  checkboxOptions?: ConfirmCheckboxOptions;
  additionalContent?: React.ReactNode;
  confirmButtonLoading?: boolean;
  customTheme?: Theme;
  sx?: SxProps<Theme>;
};

export default function ConfirmModal({
  isConfirmOpen,
  confirm,
  discard,
  confirmOptions: {
    title,
    description,
    discardButtonText,
    confirmButtonText,
    confirmButtonDisabled,
    confirmButtonProps,
    discardButtonProps,
  },
  checkboxOptions,
  additionalContent,
  confirmButtonLoading = false,
  customTheme,
  sx,
}: Props) {
  const [checkboxValue, setCheckboxValue] = useState<boolean>(
    checkboxOptions?.defaultValue || false
  );

  useSelectiveEffect(() => {
    if (
      isConfirmOpen &&
      checkboxOptions &&
      checkboxValue !== checkboxOptions.defaultValue
    )
      setCheckboxValue(checkboxOptions?.defaultValue);
  }, [isConfirmOpen]);

  const handleDiscard = () => discard(checkboxValue);
  const handleConfirm = () => confirm(checkboxValue);

  const baseTheme = useTheme();
  const theme = customTheme || baseTheme;

  return (
    <ThemeProvider theme={theme}>
      <Modal sx={{ zIndex: 10000 }} open={isConfirmOpen} disableEscapeKeyDown>
        <Box sx={{ ...style, ...sx }}>
          {typeof title === "string" ? (
            <Typography variant="h3">{title}</Typography>
          ) : (
            title
          )}

          <Box sx={{ maxHeight: "300px", overflow: "auto" }}>
            {typeof description === "string" ? (
              <Typography sx={{ color: "secondary.main", mt: 2, mb: 4 }}>
                {description}
              </Typography>
            ) : (
              description
            )}

            {additionalContent}
            {checkboxOptions && (
              <>
                <AdditionalCheckbox
                  label={checkboxOptions.label}
                  fieldName="confirm-email-checkbox"
                  value={checkboxValue}
                  setValue={setCheckboxValue}
                />
                {checkboxOptions.renderAfterCheckboxAdditionalContent?.(
                  checkboxValue
                )}
              </>
            )}
          </Box>

          <Stack
            spacing={1}
            direction="row"
            sx={{
              justifyContent: "center",
              width: "100%",
            }}
          >
            {discardButtonText && (
              <Button
                variant="outlined"
                onClick={handleDiscard}
                fullWidth
                {...discardButtonProps}
                sx={{
                  borderRadius: "16px",
                  py: 2,
                  height: "56px",
                  minWidth: "140px",
                  ...discardButtonProps?.sx,
                }}
              >
                {discardButtonText}
              </Button>
            )}
            {confirmButtonText && (
              <LoadingButton
                variant="contained"
                onClick={handleConfirm}
                fullWidth
                loading={confirmButtonLoading}
                disabled={confirmButtonDisabled}
                {...confirmButtonProps}
                sx={{
                  borderRadius: "16px",
                  py: 2,
                  height: "56px",
                  minWidth: discardButtonText ? "140px" : "280px",
                  ...confirmButtonProps?.sx,
                }}
              >
                {confirmButtonText}
              </LoadingButton>
            )}
          </Stack>
        </Box>
      </Modal>
    </ThemeProvider>
  );
}
