import { Autocomplete, AutocompleteProps, ListItem } from "@mui/material";
import React, { ReactNode } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import FetchMoreLoader from "../loaders/fetchMoreLoader";

type InfiniteScrollAutocompleteProps<T> = {
  loading: boolean;
  hasNextPage: boolean;
  fetchNextPage: () => Promise<void>;
  rootMargin?: string;
  disabled?: boolean;
  renderOption: (props, option) => ReactNode;
} & AutocompleteProps<T, false, false, false>;

export default function InfiniteScrollAutocomplete<T>({
  loading,
  hasNextPage,
  fetchNextPage,
  rootMargin = "0px 0px 400px 0px",
  disabled,
  renderOption,
  ...autocompleteProps
}: InfiniteScrollAutocompleteProps<T>) {
  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: fetchNextPage,
    disabled,
    rootMargin,
  });

  const _renderOption = (props, option) => {
    if (option.id === "sentry") {
      if (loading || hasNextPage) {
        return (
          <ListItem ref={sentryRef} key={option.id}>
            <FetchMoreLoader />
          </ListItem>
        );
      }

      return null;
    }

    return renderOption(props, option);
  };

  const options = autocompleteProps.options.length
    ? [...autocompleteProps.options, { id: "sentry" } as unknown as T]
    : [];

  return (
    <Autocomplete<T>
      {...autocompleteProps}
      loading={loading}
      renderInput={autocompleteProps.renderInput}
      options={options}
      renderOption={_renderOption}
      slotProps={{
        listbox: {
          ref: rootRef,
        },
        ...autocompleteProps.slotProps,
      }}
    />
  );
}
