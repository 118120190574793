import {
  Button,
  ButtonProps,
  SxProps,
  Theme,
  Typography,
  TypographyProps,
} from "@mui/material";

export type TextButtonProps = {
  textVariant?: TypographyProps["variant"];
  textSx?: SxProps<Theme>;
  children?: string;
} & ButtonProps;

export default function TextButton({
  onClick,
  textVariant = "buttonSmall",
  sx,
  textSx,
  children,
  ...props
}: TextButtonProps) {
  return (
    <Button
      variant="text"
      onClick={onClick}
      disableRipple
      sx={{
        padding: 0,
        py: "2px",
        "&:hover": {
          backgroundColor: "transparent",
          opacity: 0.8,
        },
        ...sx,
      }}
      {...props}
    >
      <Typography variant={textVariant} sx={textSx}>
        {children}
      </Typography>
    </Button>
  );
}
