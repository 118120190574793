import { useFragment } from "@apollo/client";
import { useMemo } from "react";
import toast from "react-hot-toast";
import { FeaturePermission } from "@/__generated__/featurePermissions";
import { hasRole, useUser } from "@/auth/useUser";
import { DropdownMenuItem } from "@/components/common/dropdownMenu/dropdownMenu";
import { ClientInterestInPackageSuggestionsFragment } from "@/graphql/fragments/packageSuggestions/clientInterestInPackageSuggestions.graphql.types";
import { ClientInterestInPackageSuggestionsFragmentDoc } from "@/graphql/fragments/packageSuggestions/clientInterestInPackageSuggestions.graphql.types";
import { useUpdateClientPackageSuggestionsInterestMutation } from "@/graphql/mutations/packageSuggestions/updateClientPackageSuggestionsInterest.graphql.types";
import useFeatureFlags from "@/hooks/common/useFeatureFlags";
import { MEDICAL_DIRECTOR, PROVIDER } from "@/types";
import useErrorLogger from "@/utils/useErrorLogger";

function useClientInterestInPackageSuggestionsFragment(clientId?: string) {
  return useFragment<ClientInterestInPackageSuggestionsFragment>({
    fragment: ClientInterestInPackageSuggestionsFragmentDoc,
    fragmentName: "ClientInterestInPackageSuggestions",
    from: {
      __typename: "client",
      id: clientId,
    },
  });
}

export function usePackageSuggestionsActions(clientId: string) {
  const logError = useErrorLogger();

  const { data } = useClientInterestInPackageSuggestionsFragment(clientId);

  const [updateClientInterestMutation] =
    useUpdateClientPackageSuggestionsInterestMutation();

  const { user, hasFeaturePermission } = useUser();

  const canViewPackageSuggestions = !hasRole(user, [MEDICAL_DIRECTOR]);

  const interestedInPackageSuggestions = useMemo(() => {
    if (!canViewPackageSuggestions) return false;

    return data?.interestedInPackageSuggestions === false ? false : true;
  }, [data, canViewPackageSuggestions]);

  const handleUpdateClientInterest = async () => {
    if (!clientId) throw new Error("Client information missing");

    try {
      await toast.promise(
        updateClientInterestMutation({
          variables: {
            clientId,
            interestedInPackageSuggestions: !interestedInPackageSuggestions,
          },
        }),
        interestedInPackageSuggestions === false
          ? {
              loading: "Marking client as interested...",
              success: "Client marked as interested!",
              error: "Failed to mark client as interested",
            }
          : {
              loading: "Marking client as not interested...",
              success: "Client marked as not interested!",
              error: "Failed to mark client as not interested",
            }
      );
    } catch (errors) {
      logError(errors);
    }
  };

  const hasPermissionsToUpdateClientInterest = hasFeaturePermission(
    FeaturePermission.UPDATE_CLIENT_INTEREST_IN_PACKAGE_SUGGESTIONS,
    [PROVIDER]
  );

  const clientInterestActions = hasPermissionsToUpdateClientInterest
    ? [
        // We show the action if interestedInPaymentPlans is false or null
        !interestedInPackageSuggestions && {
          title: "Show package suggestions",
          action: handleUpdateClientInterest,
        },
        // We show the action if interestedInPaymentPlans is true or null
        interestedInPackageSuggestions && {
          title: "Hide package suggestions",
          action: handleUpdateClientInterest,
        },
      ].filter(Boolean)
    : [];

  return {
    hasPermissionsToUpdateClientInterest,
    interestedInPackageSuggestions:
      // If its null, we show the action to show the package suggestions
      interestedInPackageSuggestions === false ? false : true,
    clientInterestActions,
    togglePackageSuggestions: handleUpdateClientInterest,
  };
}

export function usePackageSuggestionsDropdownMenuItems(
  clientId: string
): DropdownMenuItem[] {
  const { clientInterestActions } = usePackageSuggestionsActions(clientId);
  const { packagesSuggestionsV1Enabled } = useFeatureFlags();

  const interestActions = clientInterestActions.map(({ title, action }) => ({
    component: title,
    onClick: action,
  }));

  const packageSuggestionsActions: DropdownMenuItem[] = [...interestActions];

  return packagesSuggestionsV1Enabled ? packageSuggestionsActions : [];
}
