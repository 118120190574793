// @ts-nocheck
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../../__generated__/schema.graphql.types";

const defaultOptions = {} as const;
export type UpdateClientPackageSuggestionsInterestMutationVariables =
  Types.Exact<{
    clientId: Types.Scalars["bigint"]["input"];
    interestedInPackageSuggestions: Types.Scalars["Boolean"]["input"];
  }>;

export type UpdateClientPackageSuggestionsInterestMutation = {
  __typename?: "mutation_root";
  updateClientByPk?: {
    __typename?: "client";
    id: string;
    interestedInPackageSuggestions?: boolean | null;
  } | null;
};

export const UpdateClientPackageSuggestionsInterestDocument = gql`
  mutation UpdateClientPackageSuggestionsInterest(
    $clientId: bigint!
    $interestedInPackageSuggestions: Boolean!
  ) {
    updateClientByPk(
      pkColumns: { id: $clientId }
      _set: { interestedInPackageSuggestions: $interestedInPackageSuggestions }
    ) {
      id
      interestedInPackageSuggestions
    }
  }
`;
export type UpdateClientPackageSuggestionsInterestMutationFn =
  Apollo.MutationFunction<
    UpdateClientPackageSuggestionsInterestMutation,
    UpdateClientPackageSuggestionsInterestMutationVariables
  >;

/**
 * __useUpdateClientPackageSuggestionsInterestMutation__
 *
 * To run a mutation, you first call `useUpdateClientPackageSuggestionsInterestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientPackageSuggestionsInterestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientPackageSuggestionsInterestMutation, { data, loading, error }] = useUpdateClientPackageSuggestionsInterestMutation({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      interestedInPackageSuggestions: // value for 'interestedInPackageSuggestions'
 *   },
 * });
 */
export function useUpdateClientPackageSuggestionsInterestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClientPackageSuggestionsInterestMutation,
    UpdateClientPackageSuggestionsInterestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateClientPackageSuggestionsInterestMutation,
    UpdateClientPackageSuggestionsInterestMutationVariables
  >(UpdateClientPackageSuggestionsInterestDocument, options);
}
export type UpdateClientPackageSuggestionsInterestMutationHookResult =
  ReturnType<typeof useUpdateClientPackageSuggestionsInterestMutation>;
export type UpdateClientPackageSuggestionsInterestMutationResult =
  Apollo.MutationResult<UpdateClientPackageSuggestionsInterestMutation>;
export type UpdateClientPackageSuggestionsInterestMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateClientPackageSuggestionsInterestMutation,
    UpdateClientPackageSuggestionsInterestMutationVariables
  >;
