import { BusinessHoursInput } from "@fullcalendar/core";
import { Box } from "@mui/material";
import { differenceInWeeks, startOfToday } from "date-fns";
import React, { RefObject } from "react";
import TimeSelector, {
  TimeSelectorRef,
} from "@/components/booking/timeSelector/timeSelector";
import AdditionalInfo from "@/components/common/additionalInfo/additionalInfo";
import { ResourcesAdditionalInfo } from "@/components/serviceFlow/visits/createVisit/selectTime";
import { CalendarEvent } from "@/hooks/visits/useCalendarEvents";
import { Resource } from "@/hooks/visits/useCreateVisit";

type Props = {
  usesResources: boolean;
  resources: Resource;
  providerBusinessHours: BusinessHoursInput;
  start: Date;
  servicesDuration: number;
  timeSelectorRef: RefObject<TimeSelectorRef | null>;
  calendarEvents: CalendarEvent[];
  setEnd: (end: Date) => void;
  setStart: (start: Date) => void;
  isRebooking: boolean;
};
export const CreateVisitDrawerTimeSelection = ({
  usesResources,
  resources,
  providerBusinessHours,
  start,
  servicesDuration,
  timeSelectorRef,
  calendarEvents,
  setEnd,
  setStart,
  isRebooking,
}: Props) => {
  const today = startOfToday();

  const weeksDifference = differenceInWeeks(start, today, {
    roundingMethod: "ceil",
  });

  return (
    <Box
      sx={{
        width: "360px",
      }}
    >
      {usesResources && (
        <Box
          sx={{
            mb: 2,
            backgroundColor: "background.default",
            borderBottomLeftRadius: 8,
            borderBottomRightRadius: 8,
          }}
        >
          <ResourcesAdditionalInfo resources={resources} />
        </Box>
      )}
      <TimeSelector
        disableBookingOver24Hours
        businessHours={providerBusinessHours}
        currentDate={start}
        defaultDurationInMinutes={servicesDuration}
        ref={timeSelectorRef}
        events={calendarEvents}
        onSetEndDate={setEnd}
        onSetStartDate={setStart}
        slots={{
          timePickerAdditionalInfo: isRebooking ? (
            <AdditionalInfo
              variant="info"
              text={
                <>
                  Selected date is <strong>{weeksDifference} weeks</strong> from
                  today
                </>
              }
              sx={{ my: 1 }}
            />
          ) : null,
        }}
      />
    </Box>
  );
};
