// @ts-nocheck
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../__generated__/schema.graphql.types";

const defaultOptions = {} as const;
export type GetClientIdByTokenQueryVariables = Types.Exact<{
  token: Types.Scalars["String"]["input"];
}>;

export type GetClientIdByTokenQuery = {
  __typename?: "query_root";
  clientAccessTokenByPk?: {
    __typename?: "clientAccessToken";
    client: { __typename?: "client"; id: string };
  } | null;
};

export const GetClientIdByTokenDocument = gql`
  query GetClientIdByToken($token: String!) {
    clientAccessTokenByPk(token: $token) {
      client {
        id
      }
    }
  }
`;

/**
 * __useGetClientIdByTokenQuery__
 *
 * To run a query within a React component, call `useGetClientIdByTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientIdByTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientIdByTokenQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useGetClientIdByTokenQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientIdByTokenQuery,
    GetClientIdByTokenQueryVariables
  > &
    (
      | { variables: GetClientIdByTokenQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClientIdByTokenQuery,
    GetClientIdByTokenQueryVariables
  >(GetClientIdByTokenDocument, options);
}
export function useGetClientIdByTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientIdByTokenQuery,
    GetClientIdByTokenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClientIdByTokenQuery,
    GetClientIdByTokenQueryVariables
  >(GetClientIdByTokenDocument, options);
}
export function useGetClientIdByTokenSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetClientIdByTokenQuery,
        GetClientIdByTokenQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetClientIdByTokenQuery,
    GetClientIdByTokenQueryVariables
  >(GetClientIdByTokenDocument, options);
}
export type GetClientIdByTokenQueryHookResult = ReturnType<
  typeof useGetClientIdByTokenQuery
>;
export type GetClientIdByTokenLazyQueryHookResult = ReturnType<
  typeof useGetClientIdByTokenLazyQuery
>;
export type GetClientIdByTokenSuspenseQueryHookResult = ReturnType<
  typeof useGetClientIdByTokenSuspenseQuery
>;
export type GetClientIdByTokenQueryResult = Apollo.QueryResult<
  GetClientIdByTokenQuery,
  GetClientIdByTokenQueryVariables
>;
