// @ts-nocheck
import { gql } from "@apollo/client";
import * as Types from "../../../__generated__/schema.graphql.types";

export type ClientInterestInPackageSuggestionsByVisitFragment = {
  __typename?: "visit";
  client: {
    __typename?: "client";
    id: string;
    interestedInPackageSuggestions?: boolean | null;
  };
};

export type ClientInterestInPackageSuggestionsFragment = {
  __typename?: "client";
  id: string;
  interestedInPackageSuggestions?: boolean | null;
};

export const ClientInterestInPackageSuggestionsByVisitFragmentDoc = gql`
  fragment ClientInterestInPackageSuggestionsByVisit on visit {
    client {
      id
      interestedInPackageSuggestions
    }
  }
`;
export const ClientInterestInPackageSuggestionsFragmentDoc = gql`
  fragment ClientInterestInPackageSuggestions on client {
    id
    interestedInPackageSuggestions
  }
`;
