import { DateRange } from "@fullcalendar/core/internal";
import { Box } from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { differenceInDays, differenceInMinutes, format } from "date-fns";
import React, { forwardRef } from "react";
import { CalendarClock } from "react-swm-icon-pack";
import { DATE_FORMATS, TIME_FORMATS } from "@/config";
import { GREY, TEXT_DISABLED, VIOLET } from "@/config/mui/colorPalette";
import { formattedTime } from "@/utils/date";
import { useTimeSelectorContext } from "./timeSelectorContext";

type Props = {
  onClick?: VoidFunction;
  durationText?: string;
  disabled?: boolean;
};

const TimeSelectorButton: React.ForwardRefRenderFunction<
  HTMLDivElement,
  Props
> = ({ onClick, durationText = "", disabled = false }, ref) => {
  const { openSelector, dateRange } = useTimeSelectorContext();
  const handleClick = () => {
    openSelector();
    onClick?.();
  };
  const dateTimeText =
    dateRange != null
      ? `${format(dateRange.start, DATE_FORMATS.DATE_PICKER)}, ${format(dateRange.start, TIME_FORMATS.TILE)} - ${format(dateRange.end, TIME_FORMATS.TILE)}`
      : "Date & time not selected yet";

  const formattedDuration = formatDuration(dateRange, durationText);

  return (
    <ListItemButton
      disabled={disabled}
      ref={ref}
      onClick={handleClick}
      sx={{
        backgroundColor: GREY[5],
        borderRadius: 2,
        gap: 2,
      }}
    >
      <Box sx={{ alignSelf: "start" }}>
        <CalendarClock color={GREY[60]} size={20} />
      </Box>
      <ListItemText
        sx={{ m: 0, mt: -0.5 }}
        primary={dateTimeText}
        secondary={formattedDuration}
        slotProps={{
          primary: {
            variant: "labelSmall",
            color: disabled ? "text.disabled" : "text.primary",
          },
          secondary: {
            variant: "labelTiny",
            color: "text.disabled",
          },
        }}
      />
      <Typography
        variant="labelSmall"
        sx={{
          color: disabled ? TEXT_DISABLED : VIOLET[90],
          alignSelf: "start",
        }}
      >
        {dateRange != null ? "Edit" : "Select"}
      </Typography>
    </ListItemButton>
  );
};

export default forwardRef(TimeSelectorButton);

const formatDuration = (dateRange: DateRange, durationText: string) => {
  if (!dateRange) return undefined;
  const { start, end } = dateRange;

  // Validate dates
  if (end <= start) return undefined;

  const fullDayInMinutes = 24 * 60;
  const daysDiff = differenceInDays(end, start);
  const totalMinutesDiff = differenceInMinutes(end, start);
  const remainingMinutes = totalMinutesDiff % fullDayInMinutes;

  const daysDisplay =
    daysDiff > 0 ? `${daysDiff} day${daysDiff > 1 ? "s" : ""} ` : "";
  const timeDisplay = formattedTime(remainingMinutes);

  return `${daysDisplay}${timeDisplay}${durationText}`;
};
