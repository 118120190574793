import FullCalendar from "@fullcalendar/react";
import { addDays } from "date-fns";
import { RefObject } from "react";
import { useSwipeable } from "react-swipeable";

export default function useCalendarNavigation(
  handleCalendarGoToDate: (date: Date) => void,
  isDayView: boolean,
  calendarRef: RefObject<FullCalendar | null>
) {
  const goToNextDay = () => {
    if (isDayView) {
      return handleCalendarGoToDate(
        addDays(calendarRef.current?.getApi().getDate(), 1)
      );
    }
    return calendarRef.current?.getApi().next();
  };

  const goToPrevDay = () => {
    if (isDayView) {
      return handleCalendarGoToDate(
        addDays(calendarRef.current?.getApi().getDate(), -1)
      );
    }

    return calendarRef.current?.getApi().prev();
  };
  // pass to wrapping div
  const swipeHandlers = useSwipeable({
    onSwipedLeft: goToNextDay,
    onSwipedRight: goToPrevDay,
    preventScrollOnSwipe: true,
  });

  return { goToNextDay, goToPrevDay, swipeHandlers };
}
