// @ts-nocheck
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../__generated__/schema.graphql.types";

const defaultOptions = {} as const;
export type MedspaConfigurationInfoQueryVariables = Types.Exact<{
  medspaId: Types.Scalars["bigint"]["input"];
}>;

export type MedspaConfigurationInfoQuery = {
  __typename?: "query_root";
  medspaByPk?: {
    __typename?: "medspa";
    id: string;
    slug: string;
    configuration?: {
      __typename?: "MedspasMedspaconfiguration";
      cancellationFeePolicyEnabled: boolean;
      maximumAdvanceBookingHours: number;
      minimumBookingNoticeHours: number;
    } | null;
  } | null;
};

export const MedspaConfigurationInfoDocument = gql`
  query MedspaConfigurationInfo($medspaId: bigint!) {
    medspaByPk(id: $medspaId) {
      id
      slug
      configuration {
        cancellationFeePolicyEnabled
        maximumAdvanceBookingHours
        minimumBookingNoticeHours
      }
    }
  }
`;

/**
 * __useMedspaConfigurationInfoQuery__
 *
 * To run a query within a React component, call `useMedspaConfigurationInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useMedspaConfigurationInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMedspaConfigurationInfoQuery({
 *   variables: {
 *      medspaId: // value for 'medspaId'
 *   },
 * });
 */
export function useMedspaConfigurationInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    MedspaConfigurationInfoQuery,
    MedspaConfigurationInfoQueryVariables
  > &
    (
      | { variables: MedspaConfigurationInfoQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MedspaConfigurationInfoQuery,
    MedspaConfigurationInfoQueryVariables
  >(MedspaConfigurationInfoDocument, options);
}
export function useMedspaConfigurationInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MedspaConfigurationInfoQuery,
    MedspaConfigurationInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MedspaConfigurationInfoQuery,
    MedspaConfigurationInfoQueryVariables
  >(MedspaConfigurationInfoDocument, options);
}
export function useMedspaConfigurationInfoSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        MedspaConfigurationInfoQuery,
        MedspaConfigurationInfoQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    MedspaConfigurationInfoQuery,
    MedspaConfigurationInfoQueryVariables
  >(MedspaConfigurationInfoDocument, options);
}
export type MedspaConfigurationInfoQueryHookResult = ReturnType<
  typeof useMedspaConfigurationInfoQuery
>;
export type MedspaConfigurationInfoLazyQueryHookResult = ReturnType<
  typeof useMedspaConfigurationInfoLazyQuery
>;
export type MedspaConfigurationInfoSuspenseQueryHookResult = ReturnType<
  typeof useMedspaConfigurationInfoSuspenseQuery
>;
export type MedspaConfigurationInfoQueryResult = Apollo.QueryResult<
  MedspaConfigurationInfoQuery,
  MedspaConfigurationInfoQueryVariables
>;
