import { Stack, Typography } from "@mui/material";
import RotatingLoaderIcon from "@/components/common/icons/rotatingLoaderIcon";
import InfoCard from "@/components/common/infoCard/infoCard";
import PageContainer from "@/components/common/pageContainer/pageContainer";

export default function Maintenance() {
  return (
    <PageContainer
      sx={{
        height: "100vh",
        justifyContent: "center",
      }}
    >
      <InfoCard
        sx={{
          mb: 2,
          maxWidth: "384px",
          alignSelf: "center",
          "& .MuiCardContent-root": {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          },
        }}
      >
        <RotatingLoaderIcon />
        <Stack sx={{ mt: 3, textAlign: "center" }} spacing={1}>
          <Typography variant="h3">
            Moxie Suite is currently undergoing scheduled maintenance
          </Typography>
          <Typography
            sx={{
              color: "secondary.main",
            }}
          >
            Please check back soon. If you have any questions please reach out
            to your practice success manager.
          </Typography>
        </Stack>
      </InfoCard>
    </PageContainer>
  );
}
