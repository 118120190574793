// @ts-nocheck
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../__generated__/schema.graphql.types";

const defaultOptions = {} as const;
export type ServiceMenuItemFragment = {
  __typename?: "MedspaServiceMenuItem";
  id: string;
  name: string;
  isOnlineBookingEnabled: boolean;
  isRoomUsed: boolean;
  isVariablePricing: boolean;
  description: string;
  durationInMinutes: number;
  price: string;
  salesTaxRate: string;
  appointmentType: string;
  medspaServiceOfferings: Array<{
    __typename?: "MedspaServiceMenuItemMedspaServiceOfferings";
    id: string;
    serviceOffering: {
      __typename?: "MedspaServiceOffering";
      id: string;
      serviceType: { __typename?: "ServiceType"; id: string; name: string };
    };
  }>;
  oldCategory?: {
    __typename?: "serviceCategory";
    id: string;
    name: string;
  } | null;
  eligibleProviders: Array<{
    __typename?: "MedspaServiceMenuItemProviders";
    id: string;
    userMedspa: {
      __typename?: "userMedspa";
      id: string;
      user: {
        __typename?: "user";
        id: string;
        firstName: string;
        lastName: string;
      };
    };
  }>;
};

export type ServiceMenuItemsQueryVariables = Types.Exact<{
  medspaId: Types.Scalars["bigint"]["input"];
}>;

export type ServiceMenuItemsQuery = {
  __typename?: "query_root";
  medspaServiceMenuItem: Array<{
    __typename?: "MedspaServiceMenuItem";
    order?: number | null;
    id: string;
    name: string;
    isOnlineBookingEnabled: boolean;
    isRoomUsed: boolean;
    isVariablePricing: boolean;
    description: string;
    durationInMinutes: number;
    price: string;
    salesTaxRate: string;
    appointmentType: string;
    medspaServiceOfferings: Array<{
      __typename?: "MedspaServiceMenuItemMedspaServiceOfferings";
      id: string;
      serviceOffering: {
        __typename?: "MedspaServiceOffering";
        id: string;
        serviceType: { __typename?: "ServiceType"; id: string; name: string };
      };
    }>;
    oldCategory?: {
      __typename?: "serviceCategory";
      id: string;
      name: string;
    } | null;
    eligibleProviders: Array<{
      __typename?: "MedspaServiceMenuItemProviders";
      id: string;
      userMedspa: {
        __typename?: "userMedspa";
        id: string;
        user: {
          __typename?: "user";
          id: string;
          firstName: string;
          lastName: string;
        };
      };
    }>;
  }>;
};

export type ServiceMenuItemsBasicInfoQueryVariables = Types.Exact<{
  medspaId: Types.Scalars["bigint"]["input"];
  excludeVariablePricing?: Types.InputMaybe<Types.Scalars["Boolean"]["input"]>;
}>;

export type ServiceMenuItemsBasicInfoQuery = {
  __typename?: "query_root";
  medspaServiceMenuItem: Array<{
    __typename?: "MedspaServiceMenuItem";
    id: string;
    name: string;
    price: string;
    durationInMinutes: number;
    salesTaxRate: string;
    appointmentType: string;
    isPrescription: boolean;
    isVariablePricing?: boolean;
    eligibleProviders: Array<{
      __typename?: "MedspaServiceMenuItemProviders";
      id: string;
      userMedspa: { __typename?: "userMedspa"; id: string };
    }>;
  }>;
};

export type ServiceMenuItemQueryVariables = Types.Exact<{
  serviceId: Types.Scalars["bigint"]["input"];
}>;

export type ServiceMenuItemQuery = {
  __typename?: "query_root";
  medspaServiceMenuItemByPk?: {
    __typename?: "MedspaServiceMenuItem";
    id: string;
    name: string;
    isOnlineBookingEnabled: boolean;
    isRoomUsed: boolean;
    isVariablePricing: boolean;
    description: string;
    durationInMinutes: number;
    price: string;
    salesTaxRate: string;
    appointmentType: string;
    medspaServiceOfferings: Array<{
      __typename?: "MedspaServiceMenuItemMedspaServiceOfferings";
      id: string;
      serviceOffering: {
        __typename?: "MedspaServiceOffering";
        id: string;
        serviceType: { __typename?: "ServiceType"; id: string; name: string };
      };
    }>;
    oldCategory?: {
      __typename?: "serviceCategory";
      id: string;
      name: string;
    } | null;
    eligibleProviders: Array<{
      __typename?: "MedspaServiceMenuItemProviders";
      id: string;
      userMedspa: {
        __typename?: "userMedspa";
        id: string;
        user: {
          __typename?: "user";
          id: string;
          firstName: string;
          lastName: string;
        };
      };
    }>;
  } | null;
};

export const ServiceMenuItemFragmentDoc = gql`
  fragment ServiceMenuItem on MedspaServiceMenuItem {
    id
    name
    isOnlineBookingEnabled
    isRoomUsed
    isVariablePricing
    description
    durationInMinutes
    price
    salesTaxRate
    appointmentType
    medspaServiceOfferings {
      id
      serviceOffering {
        id
        serviceType {
          id
          name
        }
      }
    }
    oldCategory {
      id
      name
    }
    eligibleProviders(
      where: { userMedspa: { active: { _eq: true } } }
      orderBy: { userMedspa: { user: { firstName: ASC } } }
    ) {
      id
      userMedspa {
        id
        user {
          id
          firstName
          lastName
        }
      }
    }
  }
`;
export const ServiceMenuItemsDocument = gql`
  query ServiceMenuItems($medspaId: bigint!) {
    medspaServiceMenuItem(
      where: {
        medspa: { id: { _eq: $medspaId } }
        deletedAt: { _isNull: true }
      }
      orderBy: [{ order: ASC }, { name: ASC }]
    ) {
      ...ServiceMenuItem
      order
    }
  }
  ${ServiceMenuItemFragmentDoc}
`;

/**
 * __useServiceMenuItemsQuery__
 *
 * To run a query within a React component, call `useServiceMenuItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceMenuItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceMenuItemsQuery({
 *   variables: {
 *      medspaId: // value for 'medspaId'
 *   },
 * });
 */
export function useServiceMenuItemsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ServiceMenuItemsQuery,
    ServiceMenuItemsQueryVariables
  > &
    (
      | { variables: ServiceMenuItemsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ServiceMenuItemsQuery, ServiceMenuItemsQueryVariables>(
    ServiceMenuItemsDocument,
    options
  );
}
export function useServiceMenuItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ServiceMenuItemsQuery,
    ServiceMenuItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ServiceMenuItemsQuery,
    ServiceMenuItemsQueryVariables
  >(ServiceMenuItemsDocument, options);
}
export function useServiceMenuItemsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ServiceMenuItemsQuery,
        ServiceMenuItemsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ServiceMenuItemsQuery,
    ServiceMenuItemsQueryVariables
  >(ServiceMenuItemsDocument, options);
}
export type ServiceMenuItemsQueryHookResult = ReturnType<
  typeof useServiceMenuItemsQuery
>;
export type ServiceMenuItemsLazyQueryHookResult = ReturnType<
  typeof useServiceMenuItemsLazyQuery
>;
export type ServiceMenuItemsSuspenseQueryHookResult = ReturnType<
  typeof useServiceMenuItemsSuspenseQuery
>;
export type ServiceMenuItemsQueryResult = Apollo.QueryResult<
  ServiceMenuItemsQuery,
  ServiceMenuItemsQueryVariables
>;
export const ServiceMenuItemsBasicInfoDocument = gql`
  query ServiceMenuItemsBasicInfo(
    $medspaId: bigint!
    $excludeVariablePricing: Boolean = false
  ) {
    medspaServiceMenuItem(
      where: {
        medspa: { id: { _eq: $medspaId } }
        deletedAt: { _isNull: true }
      }
    ) {
      id
      name
      price
      durationInMinutes
      salesTaxRate
      appointmentType
      isPrescription
      isVariablePricing @skip(if: $excludeVariablePricing)
      eligibleProviders(where: { userMedspa: { active: { _eq: true } } }) {
        id
        userMedspa {
          id
        }
      }
    }
  }
`;

/**
 * __useServiceMenuItemsBasicInfoQuery__
 *
 * To run a query within a React component, call `useServiceMenuItemsBasicInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceMenuItemsBasicInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceMenuItemsBasicInfoQuery({
 *   variables: {
 *      medspaId: // value for 'medspaId'
 *      excludeVariablePricing: // value for 'excludeVariablePricing'
 *   },
 * });
 */
export function useServiceMenuItemsBasicInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    ServiceMenuItemsBasicInfoQuery,
    ServiceMenuItemsBasicInfoQueryVariables
  > &
    (
      | { variables: ServiceMenuItemsBasicInfoQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ServiceMenuItemsBasicInfoQuery,
    ServiceMenuItemsBasicInfoQueryVariables
  >(ServiceMenuItemsBasicInfoDocument, options);
}
export function useServiceMenuItemsBasicInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ServiceMenuItemsBasicInfoQuery,
    ServiceMenuItemsBasicInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ServiceMenuItemsBasicInfoQuery,
    ServiceMenuItemsBasicInfoQueryVariables
  >(ServiceMenuItemsBasicInfoDocument, options);
}
export function useServiceMenuItemsBasicInfoSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ServiceMenuItemsBasicInfoQuery,
        ServiceMenuItemsBasicInfoQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ServiceMenuItemsBasicInfoQuery,
    ServiceMenuItemsBasicInfoQueryVariables
  >(ServiceMenuItemsBasicInfoDocument, options);
}
export type ServiceMenuItemsBasicInfoQueryHookResult = ReturnType<
  typeof useServiceMenuItemsBasicInfoQuery
>;
export type ServiceMenuItemsBasicInfoLazyQueryHookResult = ReturnType<
  typeof useServiceMenuItemsBasicInfoLazyQuery
>;
export type ServiceMenuItemsBasicInfoSuspenseQueryHookResult = ReturnType<
  typeof useServiceMenuItemsBasicInfoSuspenseQuery
>;
export type ServiceMenuItemsBasicInfoQueryResult = Apollo.QueryResult<
  ServiceMenuItemsBasicInfoQuery,
  ServiceMenuItemsBasicInfoQueryVariables
>;
export const ServiceMenuItemDocument = gql`
  query ServiceMenuItem($serviceId: bigint!) {
    medspaServiceMenuItemByPk(id: $serviceId) {
      ...ServiceMenuItem
      medspaServiceOfferings {
        id
        serviceOffering {
          id
          serviceType {
            id
            name
          }
        }
      }
    }
  }
  ${ServiceMenuItemFragmentDoc}
`;

/**
 * __useServiceMenuItemQuery__
 *
 * To run a query within a React component, call `useServiceMenuItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceMenuItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceMenuItemQuery({
 *   variables: {
 *      serviceId: // value for 'serviceId'
 *   },
 * });
 */
export function useServiceMenuItemQuery(
  baseOptions: Apollo.QueryHookOptions<
    ServiceMenuItemQuery,
    ServiceMenuItemQueryVariables
  > &
    (
      | { variables: ServiceMenuItemQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ServiceMenuItemQuery, ServiceMenuItemQueryVariables>(
    ServiceMenuItemDocument,
    options
  );
}
export function useServiceMenuItemLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ServiceMenuItemQuery,
    ServiceMenuItemQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ServiceMenuItemQuery,
    ServiceMenuItemQueryVariables
  >(ServiceMenuItemDocument, options);
}
export function useServiceMenuItemSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ServiceMenuItemQuery,
        ServiceMenuItemQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ServiceMenuItemQuery,
    ServiceMenuItemQueryVariables
  >(ServiceMenuItemDocument, options);
}
export type ServiceMenuItemQueryHookResult = ReturnType<
  typeof useServiceMenuItemQuery
>;
export type ServiceMenuItemLazyQueryHookResult = ReturnType<
  typeof useServiceMenuItemLazyQuery
>;
export type ServiceMenuItemSuspenseQueryHookResult = ReturnType<
  typeof useServiceMenuItemSuspenseQuery
>;
export type ServiceMenuItemQueryResult = Apollo.QueryResult<
  ServiceMenuItemQuery,
  ServiceMenuItemQueryVariables
>;
