import { Box, Typography } from "@mui/material";
import { format } from "date-fns";
import toast from "react-hot-toast";
import { FeaturePermission } from "@/__generated__/featurePermissions";
import { useUser } from "@/auth/useUser";
import { DropdownMenuItem } from "@/components/common/dropdownMenu/dropdownMenu";
import { CherryApplicationStatus } from "@/components/common/paymentPlans/cherryStatus";
import { useAnalytics } from "@/components/common/segment/AnalyticsContext";
import { DropdownAction } from "@/components/dropdown/dropdown";
import { DATE_FORMATS } from "@/config";
import { TEXT_SECONDARY } from "@/config/mui/colorPalette";
import { useSendCherryLinkToClientMutation } from "@/graphql/mutations/paymentPlans/sendCherryLinkToClient.graphql.types";
import { useUpdateClientPaymentPlansInterestMutation } from "@/graphql/mutations/paymentPlans/updateClientPaymentPlansInterest.graphql.types";
import useMedspaTimezone from "@/hooks/common/useMedspaTimezone";
import { PROVIDER } from "@/types";
import useErrorLogger from "@/utils/useErrorLogger";

export type ClientData = {
  id: string;
  interestedInPaymentPlans?: boolean;
  cherryApplicationLinkLastSentAt?: string;
};

function usePaymentPlansActions(
  client: ClientData,
  status: CherryApplicationStatus
) {
  const logError = useErrorLogger();
  const analytics = useAnalytics();
  const [sendCherryLinkMutation] = useSendCherryLinkToClientMutation();
  const [updateClientInterestMutation] =
    useUpdateClientPaymentPlansInterestMutation();

  const { hasFeaturePermission } = useUser();

  const sendCherryLink = async () => {
    const { data } = await sendCherryLinkMutation({
      variables: { clientId: client.id },
      update(cache) {
        cache.modify({
          id: `client:${client.id}`,
          fields: {
            cherryApplicationLinkLastSentAt() {
              return new Date().toISOString();
            },
          },
        });
      },
    });

    if (!data?.sendCherryApplicationLinkToClient) {
      throw new Error("Failed to send Cherry application link");
    }
    const { ok, message } = data.sendCherryApplicationLinkToClient;
    if (!ok) throw new Error(message);
  };

  const handleSendLink = async () => {
    analytics.track("Send Cherry Link to Client Clicked");

    try {
      await toast.promise(sendCherryLink(), {
        loading: "Sending link...",
        success: "Link sent!",
        error: "Failed to send link",
      });
    } catch (errors) {
      logError(errors);
    }
  };

  const interestedInPaymentPlans = client?.interestedInPaymentPlans;
  const linkLastSentAt = client?.cherryApplicationLinkLastSentAt;

  const handleUpdateClientInterest = async (
    willBeInterestedInPaymentPlans: boolean
  ) => {
    if (!client) throw new Error("Client information missing");

    try {
      await toast.promise(
        updateClientInterestMutation({
          variables: {
            clientId: client.id,
            interestedInPaymentPlans: willBeInterestedInPaymentPlans,
          },
        }),
        willBeInterestedInPaymentPlans === true
          ? {
              loading: "Marking client as interested...",
              success: "Client marked as interested!",
              error: "Failed to mark client as interested",
            }
          : {
              loading: "Marking client as not interested...",
              success: "Client marked as not interested!",
              error: "Failed to mark client as not interested",
            }
      );
    } catch (errors) {
      logError(errors);
    }
  };

  const canSendCherryLink = hasFeaturePermission(
    FeaturePermission.SEND_CHERRY_LINK_TO_CLIENT,
    [PROVIDER]
  );
  const hasPermissionsToUpdateClientInterest = hasFeaturePermission(
    FeaturePermission.UPDATE_CLIENT_INTEREST_IN_PAYMENT_PLANS,
    [PROVIDER]
  );

  const canUpdateClientInterest =
    hasPermissionsToUpdateClientInterest &&
    [
      CherryApplicationStatus.NOT_INTERESTED,
      CherryApplicationStatus.HAS_NOT_APPLIED,
    ].includes(status);

  const cherryLinkActionTitle = linkLastSentAt
    ? "Resend Cherry application link via SMS"
    : "Send Cherry application link via SMS";

  const clientInterestActions = canUpdateClientInterest
    ? [
        // We show the action if interestedInPaymentPlans is false or null
        interestedInPaymentPlans !== true && {
          title: "Mark client as interested in payment plans",
          action: () => handleUpdateClientInterest(true),
        },
        // We show the action if interestedInPaymentPlans is true or null
        interestedInPaymentPlans !== false && {
          title: "Mark client as not interested in payment plans",
          action: () => handleUpdateClientInterest(false),
        },
      ].filter(Boolean)
    : [];

  return {
    handleSendLink,
    cherryLinkActionTitle,
    canSendCherryLink,
    clientInterestActions,
  };
}

export function usePaymentPlansDropdownActions(
  client: ClientData,
  status: CherryApplicationStatus
): DropdownAction[] {
  const {
    canSendCherryLink,
    cherryLinkActionTitle,
    clientInterestActions,
    handleSendLink,
  } = usePaymentPlansActions(client, status);

  const paymentPlansActions: DropdownAction[] = [
    canSendCherryLink && {
      title: cherryLinkActionTitle,
      action: handleSendLink,
    },
    ...clientInterestActions,
  ].filter(Boolean);

  return paymentPlansActions;
}

export function usePaymentPlansDropdownMenuItems(
  client: ClientData,
  status: CherryApplicationStatus
): DropdownMenuItem[] {
  const { utcToMedspaZonedTime } = useMedspaTimezone();
  const {
    canSendCherryLink,
    cherryLinkActionTitle,
    clientInterestActions,
    handleSendLink,
  } = usePaymentPlansActions(client, status);

  const linkLastSentAt = client?.cherryApplicationLinkLastSentAt;
  const linkLastSentAtText = linkLastSentAt
    ? `Link last sent ${format(utcToMedspaZonedTime(linkLastSentAt), DATE_FORMATS.DATE_PICKER)}`
    : "Link unsent";

  const interestActions = clientInterestActions.map(
    ({ title, action }, index) => ({
      component: title,
      onClick: action,
      divider: index === clientInterestActions.length - 1,
    })
  );

  const paymentPlansActions: DropdownMenuItem[] = [
    {
      component: (
        <Box>
          <Typography variant="paragraphSmall">
            {cherryLinkActionTitle}
          </Typography>
          <Typography variant="paragraphTiny" color={TEXT_SECONDARY}>
            {linkLastSentAtText}
          </Typography>
        </Box>
      ),
      onClick: handleSendLink,
      disabled: !canSendCherryLink,
      divider: interestActions.length > 0 ? false : true,
    },
    ...interestActions,
  ];

  return paymentPlansActions;
}
