import { ClerkProvider } from "@clerk/nextjs";
import { CacheProvider, EmotionCache } from "@emotion/react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import type { AppContext, AppProps } from "next/app";
import App from "next/app";
import dynamic from "next/dynamic";
import { UserAuthContextProviderWrapper } from "@/auth/userAuthContext";
import ErrorBoundary from "@/components/common/errors/errorBoundary";
import ConfirmProvider from "@/components/providers/confirmProvider";
import clerkProviderProps from "@/config/clerk";
import createEmotionCache from "@/config/mui/createEmotionCache";
import theme from "@/config/mui/theme";
import ClientAccessTokenProvider from "@/contexts/clientAccessTokenContext";
import { CreateMdMeetingDrawerProvider } from "@/contexts/createMdMeetingDrawerContext";
import { MessagesContextProvider } from "@/contexts/messagesContext";
import { MobileAppContextProvider } from "@/contexts/mobileAppContext";
import useMuiMenuDisableScrolling from "@/hooks/misc/useMuiMenuDisableScrolling";
import { ApolloProviderWrapper } from "@/lib/apollo";
import { getIsMoxieMobile } from "@/utils/moxieMobile";
import Maintenance from "@/views/maintenance";
import "../styles/globals.css";

const BirdEatsBugWebSDK = dynamic(
  () => import("@/components/common/birdEatsBugWebSDK/birdEatsBugWebSDK"),
  {
    ssr: false,
  }
);

const AnalyticsProvider = dynamic(
  () => import("@/components/common/segment/AnalyticsContext"),
  { ssr: false }
);

const DatadogInit = dynamic(
  () => import("@/components/common/observability/datadogInit"),
  { ssr: false }
);

const Commandbar = dynamic(
  () => import("@/components/common/commandbar/commandbar"),
  { ssr: false }
);

const MainToaster = dynamic(
  () => import("@/components/common/toasts/mainToaster"),
  {
    ssr: false,
  }
);

const PWAPullToRefresh = dynamic(
  () => import("@/components/common/pwaPullToRefresh/pwaPullToRefresh"),
  {
    ssr: false,
  }
);

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
  isMoxieMobile: boolean;
}

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

const localization = {
  userButton: {
    action__addAccount: "Switch account",
  },
};

function MoxieApp({
  Component,
  pageProps,
  emotionCache = clientSideEmotionCache,
  isMoxieMobile,
}: MyAppProps) {
  const isMaintenanceActive =
    process.env.NEXT_PUBLIC_IS_MAINTENANCE_MODE_ENABLED === "True";

  // TODO: bring back ability to disable navigation on a per-page basis
  // const showNavigation = !!Component?.showNavigation;

  useMuiMenuDisableScrolling();

  return (
    <ErrorBoundary>
      <DatadogInit />
      <CacheProvider value={emotionCache}>
        <ClerkProvider
          localization={localization}
          dynamic
          {...pageProps}
          {...clerkProviderProps}
        >
          <ApolloProviderWrapper
            isMoxieMobile={isMoxieMobile}
            pageProps={pageProps}
          >
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <UserAuthContextProviderWrapper>
                <AnalyticsProvider
                  disablePageTracking={Component?.disablePageTracking}
                  writeKey={process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY}
                >
                  {/* Please remove the below error boundary once we migrate entirely to the datadog,
                    currently this ErrorBoundary tracks views in mixpanel - this is won't be necessary in the future. */}
                  <ErrorBoundary>
                    <ConfirmProvider>
                      <MessagesContextProvider>
                        <PWAPullToRefresh>
                          <ClientAccessTokenProvider>
                            <MobileAppContextProvider
                              isMoxieMobile={isMoxieMobile}
                            >
                              <CreateMdMeetingDrawerProvider>
                                {!isMaintenanceActive ? (
                                  <>
                                    <BirdEatsBugWebSDK />
                                    <Commandbar />
                                    <Component {...pageProps} />
                                    <MainToaster />
                                  </>
                                ) : (
                                  <Maintenance />
                                )}
                              </CreateMdMeetingDrawerProvider>
                            </MobileAppContextProvider>
                          </ClientAccessTokenProvider>
                        </PWAPullToRefresh>
                      </MessagesContextProvider>
                    </ConfirmProvider>
                  </ErrorBoundary>
                </AnalyticsProvider>
              </UserAuthContextProviderWrapper>
            </ThemeProvider>
          </ApolloProviderWrapper>
        </ClerkProvider>
      </CacheProvider>
    </ErrorBoundary>
  );
}

export default MoxieApp;

MoxieApp.getInitialProps = async (appContext: AppContext) => {
  // This is a workaround to fully disable Automatic Static Optimization on ALL PAGES
  // Learn more about Automatic Static Optimization here: https://nextjs.org/docs/pages/building-your-application/rendering/automatic-static-optimization
  // We don't really need ASO for Moxie, as most of our pages are dynamic and behind a login wall
  // We may want ASO for some pages in the future, for example improving the load times for the booking/online store pages
  const appProps = await App.getInitialProps(appContext);
  const isMoxieMobile = getIsMoxieMobile(appContext);
  return { ...appProps, isMoxieMobile };
};
