import { FeaturePermission } from "@/__generated__/featurePermissions";
import { useUser } from "@/auth/useUser";

export default function useMoxieBalancePermissions() {
  const {
    user,
    userMedspa,
    newPermissionsEnabledForUser,
    hasFeaturePermission,
  } = useUser();
  // you must be the provider owner or PC owner to see balance page
  const isPCOwner =
    userMedspa?.medspa.professionalCorporation?.ownerId === user?.id &&
    user?.id !== undefined;

  let isLoadingPermissions = true;
  const isLoadingPermissionsMso =
    !user?.id ||
    userMedspa?.medspa?.isMso === undefined ||
    !userMedspa?.medspa.professionalCorporation?.ownerId;

  const isLoadingPermissionsNonMso =
    !user?.id || userMedspa.medspa?.isMso === undefined;

  if (userMedspa) {
    isLoadingPermissions = userMedspa.medspa.isMso
      ? isLoadingPermissionsMso
      : isLoadingPermissionsNonMso;
  }

  const showBalance =
    (newPermissionsEnabledForUser
      ? hasFeaturePermission(FeaturePermission.VIEW_BALANCE_PAGE)
      : userMedspa?.isProviderOwner) || isPCOwner;

  return {
    showBalance,
    isLoadingPermissions,
  };
}
