import {
  Box,
  Chip,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import type { JSX } from "react";
import {
  CHIP_BG_COLOR,
  CHIP_BORDER_COLOR,
  GFE_CHIP_TEXT_COLOR,
  GfeChipType,
} from "@/components/common/gfe/gfeIndicationChip";
import { CardiologyIcon, CoinIcon } from "@/components/common/icons";
import { EventColorPalette } from "@/config";
import { BLUE, GREY, MINT, SALMON, VIOLET } from "@/config/mui/colorPalette";
import { CalendarViews } from "@/hooks/visits/useCalendarTypeView";
import {
  InvoiceStatus,
  REVIEW_STATUS_BG_COLOR,
  REVIEW_STATUS_BORDER_COLOR,
  REVIEW_STATUS_PROVIDER_LABEL,
  REVIEW_STATUS_TEXT_COLOR,
  ReviewStatus,
  VisitStatus,
} from "@/types";
import { ServiceMenuItemGfeStatus } from "@/types/gfe";
import {
  getInvoiceLabelAndColor,
  shouldShowInvoiceChip,
} from "@/utils/invoiceChip";
import { getVisitStatusLabel } from "@/utils/visit";

type EventTileChipsProps = {
  view?: CalendarViews;
  status: VisitStatus;
  reviewStatus: ReviewStatus;
  invoiceStatus: InvoiceStatus;
  gfeGeneralStatus: ServiceMenuItemGfeStatus;
  duration: number;
};

export const EventTileChips = ({
  view,
  status,
  reviewStatus,
  invoiceStatus,
  gfeGeneralStatus,
  duration,
}: EventTileChipsProps) => {
  const theme = useTheme();

  const isMobile = useMediaQuery(
    `(max-width: ${theme.breakpoints.values["ipad-hor"]}px)`
  );

  const showChips =
    view &&
    view === CalendarViews.DAY &&
    !isMobile &&
    (duration >= 35 || duration <= 25);

  const isChipSmall = duration < 30;

  const showInvoiceChip = shouldShowInvoiceChip(status, invoiceStatus);
  const showReviewStatusChip = status === VisitStatus.COMPLETED;

  return (
    <Stack flexDirection="row" gap={0.5} alignItems="center" overflow="hidden">
      {showChips && (
        <VisitStatusCalendarChip status={status} small={isChipSmall} />
      )}
      <GFEStatusChip
        gfeGeneralStatus={gfeGeneralStatus}
        duration={duration}
        view={view}
      />
      {showChips && (
        <>
          {showReviewStatusChip && (
            <ReviewStatusCalendarChip
              status={reviewStatus}
              small={isChipSmall}
            />
          )}
          {showInvoiceChip && (
            <InvoiceStatusCalendarChip
              small={isChipSmall}
              status={invoiceStatus}
            />
          )}
        </>
      )}
    </Stack>
  );
};

type GFEStatusChipProps = {
  gfeGeneralStatus: string;
  duration: number;
  view?: CalendarViews;
};

const CALENDAR_GFE_CHIP_BORDER_COLOR: Record<GfeChipType, string> = {
  ...CHIP_BORDER_COLOR,
  [GfeChipType.NOT_INDICATED]: GREY[30],
  [GfeChipType.CONTRAINDICATED]: SALMON[80],
  [GfeChipType.INDICATED_WITH_EXCEPTIONS]: MINT[80],
  [GfeChipType.INDICATED]: MINT[80],
};

const GFEStatusChip = ({
  gfeGeneralStatus,
  duration,
  view,
}: GFEStatusChipProps) => {
  const theme = useTheme();

  const isMobile = useMediaQuery(
    `(max-width: ${theme.breakpoints.values["ipad-hor"]}px)`
  );

  const showText =
    !isMobile &&
    ((duration > 30 && view === CalendarViews.DAY) || duration > 60);

  if (gfeGeneralStatus === ServiceMenuItemGfeStatus.NOT_NEEDED) return null;

  return (
    <Chip
      size="small"
      label={
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 0.5,
          }}
        >
          <CardiologyIcon
            color={GFE_CHIP_TEXT_COLOR[gfeGeneralStatus]}
            size={16}
          />
          {showText && <Typography variant="paragraphSmall">GFE</Typography>}
        </Box>
      }
      sx={{
        border: `1px solid ${CALENDAR_GFE_CHIP_BORDER_COLOR[gfeGeneralStatus]}`,
        color: GFE_CHIP_TEXT_COLOR[gfeGeneralStatus],
        backgroundColor: CHIP_BG_COLOR[gfeGeneralStatus],
        flexDirection: "row",
        height: duration < 30 ? "16px" : "24px",
      }}
    />
  );
};

const VisitStatusColors: {
  [key in VisitStatus]: EventColorPalette;
} = {
  [VisitStatus.SCHEDULED]: BLUE,
  [VisitStatus.COMPLETED]: MINT,
  [VisitStatus.CANCELLED]: SALMON,
  [VisitStatus.CANCELLED_LATE]: SALMON,
  [VisitStatus.CONFIRMED]: VIOLET,
  [VisitStatus.NO_SHOW]: SALMON,
};

const VisitStatusCalendarChip = ({
  status,
  small,
}: {
  status: VisitStatus;
  small: boolean;
}) => {
  const VisitStatusPalette = VisitStatusColors[status];

  return (
    <BaseCalendarChip
      small={small}
      label={`Appt: ${getVisitStatusLabel(status)}`}
      baseColor={VisitStatusPalette}
    />
  );
};

const ReviewStatusCalendarChip = ({
  status,
  small,
}: {
  status: ReviewStatus;
  small: boolean;
}) => {
  return (
    <Chip
      size="small"
      label={`Chart: ${REVIEW_STATUS_PROVIDER_LABEL[status]}`}
      sx={{
        border: `1px solid ${REVIEW_STATUS_BORDER_COLOR[status]}`,
        color: REVIEW_STATUS_TEXT_COLOR[status],
        backgroundColor: REVIEW_STATUS_BG_COLOR[status],
        flexDirection: "row",
        height: small ? "16px" : "24px",
      }}
    />
  );
};

const InvoiceStatusCalendarChip = ({
  status,
  small,
}: {
  status: InvoiceStatus;
  small: boolean;
}) => {
  const { label, baseColor } = getInvoiceLabelAndColor(status, true);

  const icon = <CoinIcon size={small ? 16 : 20} color={baseColor[100]} />;

  return (
    <BaseCalendarChip
      icon={icon}
      label={label}
      small={small}
      baseColor={baseColor}
    />
  );
};

type Color = {
  100: string;
  80: string;
  40: string;
};

const BaseCalendarChip = ({
  label,
  small,
  baseColor,
  icon,
}: {
  label: string;
  small: boolean;
  baseColor: Color;
  icon?: JSX.Element;
}) => {
  return (
    <Chip
      size="small"
      icon={icon}
      label={label}
      sx={{
        border: `1px solid ${baseColor[80]}`,
        color: baseColor[100],
        backgroundColor: baseColor[40],
        flexDirection: "row",
        height: small ? "16px" : "24px",
      }}
    />
  );
};
