// Medspa scoped feature flags (TODO, migrate the remaining global flags to this const)
export const FeatureFlags = {
  BALANCE_V1: "balance-v1",
  CHERRY_PAYMENTS_V1: "cherry-payments-v1",
  PACKAGES_ALLOW_RETAIL_PRODUCTS: "packages-allow-retail-products",
  MESSAGING_MAINTENANCE: "messaging-maintenance-v1",
  STANDING_ORDERS_V1: "standing-orders-v1",
  AFFIRM_PAYMENT_METHOD: "affirm-payment-method",
  VENMO_PAYMENT_METHOD: "venmo-payment-method",
  ZELLE_PAYMENT_METHOD: "zelle-payment-method",
  IS_IN_MAINTENANCE: "maintenance-banner-v1",
  CONSENT_FORM_ENHANCEMENTS_V1: "consent-form-enhancements-v1",
  INVOICE_SUMMARY_UPDATE_SERVICES: "invoice-summary-update-services",
  SYNC_GFE_V2: "sync-gfe-v2",
  MARKETING_MESSAGES_V1: "marketing-messages-v1",
  COMMAND_AI: "commandai",
  MARKETING_MESSAGES_RESPONSE_V1: "marketing-messages-response-v1",
  TELEHEALTH_SERVICES_V1: "telehealth-services-v1",
  EDIT_INVOICE_LINE_PRICES_V1: "edit-invoice-line-prices-v1",
  ORDER_PRESCRIPTION_DRAWER_V1: "order-prescription-drawer-v1",
  CONFIGURABLE_NOTIFICATIONS_V1: "configurable-notifications-v1",
  SHOPIFY_PLUS: "shopify-plus",
  ONLINE_BOOKING_DEPOSITS_V1: "online-booking-deposits-v1",
  NEW_BOOKING_STEPPER_V1: "new-booking-stepper-v1",
  MULTIPLE_STRIPE_TERMINALS_V1: "multiple-stripe-terminals-v1",
  REPORT_A_BUG_VERTICAL_NAV: "report-a-bug-vertical-nav",
  CHERRY_INTEGRATION_V1: "cherry-integration-v1",
  PACKAGES_SUGGESTIONS_V1: "packages-suggestions-v1",
  REFER_AND_EARN_V1: "refer-and-earn-v1",
  LAST_MINUTE_APPOINTMENTS_V1: "last-minute-appointments-v1",
  GLOBAL_SUPPLIES_SAVINGS_V1: "global-supplies-savings-v1",
  PAYROLL_INTEGRATION_V1: "payroll-integration-v1",
  CLIENT_MEDICAL_HISTORY_FORM_UPDATES_V1:
    "client-medical-history-form-updates-v1",
  STRIPE_NINE_PERCENT_INTEGRATION: "stripe-nine-percent-integration",
  PACKAGE_CARD_REVAMP_V1: "package-card-revamp-v1",
  IMPROVED_GFE_REQUEST_V1: "improved-gfe-request-v1",
  QUALIPHY_NEW_MAPPING: "qualiphy-new-mapping",
} as const;

export type FeatureFlag = (typeof FeatureFlags)[keyof typeof FeatureFlags];
