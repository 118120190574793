// @ts-nocheck
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../__generated__/schema.graphql.types";

const defaultOptions = {} as const;
export type CreateInvoiceMutationVariables = Types.Exact<{
  visitId?: Types.InputMaybe<Types.Scalars["BigInt"]["input"]>;
  clientId?: Types.InputMaybe<Types.Scalars["BigInt"]["input"]>;
  medspaId?: Types.InputMaybe<Types.Scalars["BigInt"]["input"]>;
  isPackageSuggestion?: Types.InputMaybe<Types.Scalars["Boolean"]["input"]>;
}>;

export type CreateInvoiceMutation = {
  __typename?: "mutation_root";
  createInvoice?: {
    __typename?: "CreateInvoice";
    message?: string | null;
    ok?: boolean | null;
    invoice?: {
      __typename?: "InvoiceType";
      id: string;
      status: string;
      uuid: string;
    } | null;
  } | null;
};

export const CreateInvoiceDocument = gql`
  mutation CreateInvoice(
    $visitId: BigInt
    $clientId: BigInt
    $medspaId: BigInt
    $isPackageSuggestion: Boolean
  ) {
    createInvoice(
      visitId: $visitId
      clientId: $clientId
      medspaId: $medspaId
      isPackageSuggestion: $isPackageSuggestion
    ) {
      message
      ok
      invoice {
        id
        status
        uuid
      }
    }
  }
`;
export type CreateInvoiceMutationFn = Apollo.MutationFunction<
  CreateInvoiceMutation,
  CreateInvoiceMutationVariables
>;

/**
 * __useCreateInvoiceMutation__
 *
 * To run a mutation, you first call `useCreateInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvoiceMutation, { data, loading, error }] = useCreateInvoiceMutation({
 *   variables: {
 *      visitId: // value for 'visitId'
 *      clientId: // value for 'clientId'
 *      medspaId: // value for 'medspaId'
 *      isPackageSuggestion: // value for 'isPackageSuggestion'
 *   },
 * });
 */
export function useCreateInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateInvoiceMutation,
    CreateInvoiceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateInvoiceMutation,
    CreateInvoiceMutationVariables
  >(CreateInvoiceDocument, options);
}
export type CreateInvoiceMutationHookResult = ReturnType<
  typeof useCreateInvoiceMutation
>;
export type CreateInvoiceMutationResult =
  Apollo.MutationResult<CreateInvoiceMutation>;
export type CreateInvoiceMutationOptions = Apollo.BaseMutationOptions<
  CreateInvoiceMutation,
  CreateInvoiceMutationVariables
>;
