// @ts-nocheck
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../../__generated__/schema.graphql.types";

const defaultOptions = {} as const;
export type SendCherryLinkToClientMutationVariables = Types.Exact<{
  clientId: Types.Scalars["ID"]["input"];
}>;

export type SendCherryLinkToClientMutation = {
  __typename?: "mutation_root";
  sendCherryApplicationLinkToClient?: {
    __typename?: "SendCherryApplicationLinkToClient";
    ok?: boolean | null;
    message?: string | null;
  } | null;
};

export const SendCherryLinkToClientDocument = gql`
  mutation SendCherryLinkToClient($clientId: ID!) {
    sendCherryApplicationLinkToClient(clientId: $clientId) {
      ok
      message
    }
  }
`;
export type SendCherryLinkToClientMutationFn = Apollo.MutationFunction<
  SendCherryLinkToClientMutation,
  SendCherryLinkToClientMutationVariables
>;

/**
 * __useSendCherryLinkToClientMutation__
 *
 * To run a mutation, you first call `useSendCherryLinkToClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendCherryLinkToClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendCherryLinkToClientMutation, { data, loading, error }] = useSendCherryLinkToClientMutation({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useSendCherryLinkToClientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendCherryLinkToClientMutation,
    SendCherryLinkToClientMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendCherryLinkToClientMutation,
    SendCherryLinkToClientMutationVariables
  >(SendCherryLinkToClientDocument, options);
}
export type SendCherryLinkToClientMutationHookResult = ReturnType<
  typeof useSendCherryLinkToClientMutation
>;
export type SendCherryLinkToClientMutationResult =
  Apollo.MutationResult<SendCherryLinkToClientMutation>;
export type SendCherryLinkToClientMutationOptions = Apollo.BaseMutationOptions<
  SendCherryLinkToClientMutation,
  SendCherryLinkToClientMutationVariables
>;
