import { SxProps, Theme } from "@mui/material";
import { ReactNode } from "react";
import { useUser } from "@/auth/useUser";
import VerticalNav from "@/components/common/navigation/verticalNav";
import TopBar from "@/components/common/topBar/topBar";
import { useBoolean } from "@/hooks/common/useBoolean";
import { useIsMoxieMobile } from "@/hooks/moxieMobile/useIsMoxieMobile";
import PageContainerWrapper from "./pageContainerWrapper";

export default function PageContainer(props: {
  children: ReactNode;
  sx?: SxProps<Theme>;
  isFullWidthContent?: boolean;
  contentParentContainerSx?: SxProps<Theme>;
  scrollParentSx?: SxProps<Theme>;
}) {
  const { children, ...otherProps } = props;
  const { user } = useUser();
  const nav = useBoolean(false);
  const { isMoxieMobile } = useIsMoxieMobile();
  const isNavigationVisible = !!user && !isMoxieMobile;

  return (
    <PageContainerWrapper
      navComponent={
        isNavigationVisible && (
          <VerticalNav openNav={nav.value} onCloseNav={nav.onFalse} />
        )
      }
      topBarComponent={
        isNavigationVisible && (
          <TopBar
            user={user}
            onToggleNav={nav.onToggle}
            isNavOpen={nav.value}
          />
        )
      }
      {...otherProps}
    >
      {children}
    </PageContainerWrapper>
  );
}
