import { useEffect } from "react";
import { buildClientTokenContext } from "@/contexts/clientAccessTokenContext";
import { useGetClientIdByTokenQuery } from "@/graphql/queries/getClientIdByToken.graphql.types";
import { clearDatadogUser, setDatadogUser } from "@/utils/datadog";

export const useDatadogTrackClientByAccessToken = (
  clientAccessToken: string,
  isClientPage: boolean
) => {
  const { data } = useGetClientIdByTokenQuery({
    variables: {
      token: clientAccessToken,
    },
    skip: !isClientPage || !clientAccessToken,
    context: buildClientTokenContext(clientAccessToken),
  });

  useDatadogTrackClient(data?.clientAccessTokenByPk?.client, isClientPage);
};

export const useDatadogTrackClient = (
  client: { id?: string } | undefined,
  isClientPage = true
) =>
  useEffect(() => {
    if (!isClientPage) {
      return;
    }

    if (client) {
      setDatadogUser({
        id: client.id,
        role: "client",
      });
    } else {
      clearDatadogUser();
    }
  }, [client, isClientPage]);
