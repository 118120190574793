import {
  Button,
  ButtonProps,
  CircularProgress,
  CircularProgressProps,
} from "@mui/material";
import TextButton, {
  TextButtonProps,
} from "@/components/common/buttons/textButton";

type LoadingButtonProps = {
  loading?: boolean;
  loadingIconProps?: CircularProgressProps;
} & ButtonProps;

type LoadingTextButtonProps = LoadingButtonProps & TextButtonProps;

export default function LoadingButton({
  loading = false,
  loadingIconProps = {},
  disabled,
  ...props
}: LoadingButtonProps) {
  return (
    <Button
      disabled={disabled || loading}
      endIcon={
        loading && (
          <CircularProgress size={20} color="secondary" {...loadingIconProps} />
        )
      }
      {...props}
    />
  );
}

export function LoadingTextButton({
  loading = false,
  loadingIconProps = {},
  disabled,
  ...props
}: LoadingTextButtonProps) {
  return (
    <TextButton
      disabled={disabled || loading}
      endIcon={
        loading && (
          <CircularProgress size={20} color="secondary" {...loadingIconProps} />
        )
      }
      {...props}
    />
  );
}
