import { yupResolver } from "@hookform/resolvers/yup";
import { addMinutes } from "date-fns";
import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import * as yup from "yup";
import { useUser, hasRole } from "@/auth/useUser";
import { useConfirm } from "@/components/providers/confirmProvider";
import { useCreateMdMeetingDrawer } from "@/contexts/createMdMeetingDrawerContext";
import { useCreateMdMeetingMutation } from "@/graphql/mutations/createMdMeeting.graphql.types";
import { useRescheduleMdMeetingMutation } from "@/graphql/mutations/rescheduleMdMeeting.graphql.types";
import { MEDICAL_DIRECTOR, PROVIDER } from "@/types";
import { hasDjangoMutationError } from "@/utils/djangoMutationError";
import useErrorLogger from "@/utils/useErrorLogger";
import useMedspaTimezone from "../common/useMedspaTimezone";
import useUserHasRoles from "../user/useUserHasRoles";

const DEFAULT_MEETING_DURATION = 30;

export type Attendee = { id: string; label: string; userId: string };

export type CreateMDMeetingForm = {
  providers: Attendee[];
  mds: Attendee[];
  meetingDetails?: string;
  format: string;
  startDate: Date;
  endDate: Date;
};

const schema = yup.object().shape({
  providers: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.string().required(),
        userId: yup.string(),
        label: yup.string().required(),
      })
    )
    .min(1, "At least one provider must attend this meeting"),
  mds: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.string().required(),
        label: yup.string().required(),
      })
    )
    .min(1, "At least one MD must attend this meeting"),
  meetingDetails: yup.string(),
  format: yup.string().required("Meeting format is required"),
  startDate: yup.date().required("Start date is required"),
  endDate: yup.date().required("End date is required"),
});

export function useCreateMdMeetingForm() {
  const { getMedspaCurrentTime } = useMedspaTimezone();
  const { userMedspa } = useUser();
  const logError = useErrorLogger();
  const { getConfirm } = useConfirm();

  const { isOpen, closeDrawer, currentMeeting, drawerTitle } =
    useCreateMdMeetingDrawer();

  const isMd = useUserHasRoles([MEDICAL_DIRECTOR]);

  const [createMdMeetingMutation, { loading: isCreating }] =
    useCreateMdMeetingMutation({
      refetchQueries: ["MdMeetingsByMedspa"],
    });

  const [rescheduleMdMeetingMutation, { loading: isRescheduling }] =
    useRescheduleMdMeetingMutation({
      refetchQueries: ["MdMeetingsByMedspa"],
    });

  const {
    control,
    reset,
    watch,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm<CreateMDMeetingForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      meetingDetails: "",
      providers: [],
      mds: [],
      format: "video_or_phone",
      startDate: null,
      endDate: null,
    },
    mode: "onChange",
  });

  const mds = watch("mds");
  const providers = watch("providers");
  const startDate = watch("startDate");
  const endDate = watch("endDate");

  const resetFields = useCallback(() => {
    // Get the current time in the medspa's timezone
    const currentDate = getMedspaCurrentTime();

    // Round the start time to the next hour
    const roundedStartDate = new Date(currentDate);
    roundedStartDate.setHours(currentDate.getHours() + 1, 0, 0, 0);

    // Calculate the end time by adding the default duration
    const roundedEndDate = addMinutes(
      roundedStartDate,
      DEFAULT_MEETING_DURATION
    );

    // Reset form fields with default values
    reset({
      meetingDetails: "",
      providers: [],
      mds: [],
      format: "video_or_phone",
      startDate: roundedStartDate,
      endDate: roundedEndDate,
    });
  }, [getMedspaCurrentTime, reset]);

  const setFieldsWithCurrentMeeting = useCallback(
    (currentMeeting) => {
      const providers = currentMeeting.attendees
        .filter((attendee) => hasRole(attendee.userMedspa, [PROVIDER]))
        .map((provider) => ({
          id: provider.userMedspa.id,
          label: provider.userMedspa.user.fullName,
          userId: provider.userMedspa.user.id,
        }));

      const mds = currentMeeting.attendees
        .filter((attendee) => hasRole(attendee.userMedspa, [MEDICAL_DIRECTOR]))
        .map((md) => ({
          id: md.userMedspa.id,
          label: md.userMedspa.user.fullName,
          userId: md.userMedspa.user.id,
        }));

      // Set form fields
      reset({
        meetingDetails: currentMeeting.details || "",
        providers,
        mds,
        format: currentMeeting.format || "video_or_phone",
        startDate: new Date(currentMeeting.startTime),
        endDate: new Date(currentMeeting.endTime),
      });
    },
    [reset]
  );

  /**
   * Setting form fields with current meeting details or empty values
   * if no meeting is selected meaning we are creating a new meeting.
   * This will run every time the drawer is opened.
   */
  useEffect(() => {
    if (isOpen) {
      if (currentMeeting) {
        // Editing mode
        setFieldsWithCurrentMeeting(currentMeeting);
      } else {
        // Creating mode
        resetFields();
      }
    }
  }, [currentMeeting, isOpen, resetFields, setFieldsWithCurrentMeeting]);

  const isCreateButtonEnabled = Boolean(
    isValid && mds.length > 0 && providers.length > 0 && startDate && endDate
  );

  const onSubmit = handleSubmit(async (data) => {
    if (!userMedspa) return;

    const attendeesIds = [
      ...data.providers.map((provider) => parseInt(provider.id)),
      ...data.mds.map((md) => parseInt(md.id)),
    ];

    const toastId = toast.loading(
      currentMeeting ? "Updating MD meeting..." : "Creating MD meeting..."
    );

    const isEditing = Boolean(currentMeeting);
    const bookingFlow = isMd ? "md_menu" : "provider_compliance_hub";

    try {
      if (isEditing) {
        const sendCommunication = await getConfirm({
          title: "Send notification to attendees",
          description:
            "Do you want to send an email to attendees with information about updated meeting details?",
          discardButtonText: "No, don't send",
          confirmButtonText: "Yes, send",
        });

        await rescheduleMdMeetingMutation({
          variables: {
            mdMeetingId: currentMeeting.id,
            bookingFlow,
            details: data.meetingDetails || "",
            endTime: data.endDate.toISOString(),
            attendeesIds: attendeesIds.map(String),
            startTime: data.startDate.toISOString(),
            sendCommunication,
            format: data.format,
          },
        });
        toast.success("MD meeting updated successfully", { id: toastId });
      } else {
        await createMdMeetingMutation({
          variables: {
            bookedById: userMedspa.id,
            bookingFlow,
            format: data.format,
            details: data.meetingDetails || "",
            endTime: data.endDate.toISOString(),
            attendeesIds: attendeesIds.map(String),
            startTime: data.startDate.toISOString(),
          },
        });
        toast.success("MD meeting created successfully", { id: toastId });
      }

      closeDrawer();
    } catch (error) {
      logError(error);
      toast.error(
        hasDjangoMutationError(error)
          ? error.message
          : `Unable to ${currentMeeting ? "update" : "create"} MD meeting. Please contact support team.`,
        { id: toastId }
      );
    }
  });

  return {
    // Drawer state
    drawerTitle,
    isOpen,
    isEditing: Boolean(currentMeeting),
    closeDrawer,

    // Form control and validation
    control,
    errors,
    isCreateButtonEnabled,
    onSubmit,
    setValue,

    // Loading state
    isLoading: isCreating || isRescheduling,

    // Form values
    startDate,
    endDate,
    providers,
  };
}
