import useCurrentProviderInfo from "./useCurrentProviderInfo";

export const useIsEligibleWeightLossPharmacies = (): {
  empower: boolean;
  strive503A: boolean;
  strive503B: boolean;
  belmar503A: boolean;
} => {
  const { data } = useCurrentProviderInfo();

  // check if provider is eligible for specific weight loss pharmacies - think of this as a userMedspa level feature flag
  return {
    empower: !!data?.userMedspa[0]?.isEligibleForEmpowerWeightLoss,
    strive503A: !!data?.userMedspa[0]?.isEligibleForStrive503aWeightLoss,
    strive503B: !!data?.userMedspa[0]?.isEligibleForStrive503bWeightLoss,
    belmar503A: !!data?.userMedspa[0]?.isEligibleForBelmar503aWeightLoss,
  };
};
