import { BusinessHoursInput } from "@fullcalendar/core";
import { DateRange } from "@fullcalendar/core/internal";
import FullCalendar from "@fullcalendar/react";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  addMinutes,
  isEqual,
  isSameDay,
  startOfDay,
  endOfDay,
  addDays,
  differenceInCalendarDays,
} from "date-fns";
import React, {
  forwardRef,
  useImperativeHandle,
  useCallback,
  useState,
  useMemo,
  useRef,
  useEffect,
  ReactNode,
} from "react";
import { interactiveCalendarProps } from "@/components/common/calendar/interactiveCalendarProps";
import MoxieFullCalendar from "@/components/common/calendar/moxieFullCalendar";
import InfoCard from "@/components/common/infoCard/infoCard";
import { TEXT_PRIMARY_DARK } from "@/config/mui/colorPalette";
import useCalendarGoToDate from "@/hooks/calendar/useCalendarGoToDate";
import useMedspaTimezone from "@/hooks/common/useMedspaTimezone";
import useDatesValidation from "@/hooks/misc/useDatesValidation";
import {
  DEFAULT_CALENDAR_HEADER_HEIGHT,
  DEFAULT_PAGE_PADDING_BOTTOM,
  getCalendarHeight,
} from "@/utils";
import { constrainToOneFullDay } from "@/utils/date";
import TimeSelectorDatePicker from "./datePicker";
import TimeSelectorTimePicker from "./timePicker";
import { useTimeSelectorContext } from "./timeSelectorContext";

export type TimeSelectorProps = {
  events: Array<{
    title: string;
    display?: string;
    start: string;
    end: string;
  }>;
  businessHours?: BusinessHoursInput;
  currentDate?: Date;
  defaultDurationInMinutes?: number;
  enableDateRange?: boolean;
  isAllDay?: boolean;
  onSetStartDate?: React.Dispatch<Date>;
  onSetEndDate?: React.Dispatch<Date>;
  slots?: {
    timePickerAdditionalInfo?: ReactNode;
  };
  disableBookingOver24Hours?: boolean;
};

export type TimeSelectorRef = {
  readonly dateRange: Partial<Readonly<DateRange>>;
  cancel: VoidFunction;
  confirm: VoidFunction;
  setStart: React.Dispatch<Date>;
  setEnd: React.Dispatch<Date>;
};

const TimeSelector: React.ForwardRefRenderFunction<
  TimeSelectorRef,
  TimeSelectorProps
> = (props, ref) => {
  const { getMedspaCurrentTime } = useMedspaTimezone();
  const {
    events,
    businessHours,
    disableBookingOver24Hours = false,
    currentDate = getMedspaCurrentTime(),
    defaultDurationInMinutes = 5,
    enableDateRange = false,
    isAllDay = false,
    onSetStartDate,
    onSetEndDate,
    slots,
  } = props;

  const { closeSelector, setEnd, setStart, dateRange } =
    useTimeSelectorContext();

  const [startDate, setStartDate] = useState<Date>(
    dateRange?.start ?? currentDate
  );
  const [endDate, setEndDate] = useState<Date>(
    dateRange?.end ?? addMinutes(startDate, defaultDurationInMinutes)
  );

  const handleCancel = useCallback(() => {
    setStartDate(currentDate);
    setEndDate(addMinutes(currentDate, defaultDurationInMinutes));
    closeSelector();
  }, [
    closeSelector,
    setStartDate,
    setEndDate,
    currentDate,
    defaultDurationInMinutes,
  ]);

  const handleConfirm = useCallback(() => {
    if (!startDate || !endDate) return;
    setStart(startDate);
    setEnd(endDate);
    closeSelector();
  }, [closeSelector, startDate, endDate, setStart, setEnd]);

  const internalDateRange = useMemo(
    () => ({ start: startDate, end: endDate }),
    [startDate, endDate]
  );

  const handleStartDateChange = useCallback(
    (date: Date) => {
      setStartDate(date);
      onSetStartDate?.(date);
    },
    [onSetStartDate, setStartDate]
  );
  const handleEndDateChange = useCallback(
    (date: Date, disableBookingOver24Hours: boolean = false) => {
      if (!disableBookingOver24Hours) {
        setEndDate(date);
        onSetEndDate?.(date);
        return;
      }

      const constrainedDate = constrainToOneFullDay(startDate, date);
      setEndDate(constrainedDate);
      onSetEndDate?.(constrainedDate);
    },
    [onSetEndDate, startDate]
  );

  useEffect(() => {
    if (isAllDay) {
      const newStartDate = startOfDay(startDate);
      const newEndDate = endOfDay(endDate);
      if (!isEqual(startDate, newStartDate)) {
        handleStartDateChange(newStartDate);
      }
      if (!isEqual(endDate, newEndDate)) {
        handleEndDateChange(newEndDate);
      }
    }
  }, [
    isAllDay,
    startDate,
    endDate,
    handleStartDateChange,
    handleEndDateChange,
  ]);

  useImperativeHandle(
    ref,
    () => ({
      dateRange: internalDateRange,
      cancel: handleCancel,
      confirm: handleConfirm,
      setStart: handleStartDateChange,
      setEnd: handleEndDateChange,
    }),
    [
      handleCancel,
      handleConfirm,
      internalDateRange,
      handleStartDateChange,
      handleEndDateChange,
    ]
  );

  const calendarRef = useRef<FullCalendar | null>(null);
  const { medspaReadableTimezone } = useMedspaTimezone();
  const { goToDate } = useCalendarGoToDate(calendarRef);

  useDatesValidation(
    startDate,
    endDate,
    handleEndDateChange,
    defaultDurationInMinutes
  );

  const handleCalendarGoToDate = (date: Date) => {
    goToDate(date);
    const updatedDate = calendarRef.current?.getApi().getDate();

    if (!updatedDate) return;

    const daysDifference = differenceInCalendarDays(endDate, startDate);

    const hours = startDate.getHours();
    const minutes = startDate.getMinutes();
    updatedDate.setHours(hours);
    updatedDate.setMinutes(minutes);
    handleStartDateChange(updatedDate);

    if (endDate) {
      const endHours = endDate.getHours();
      const endMinutes = endDate.getMinutes();
      const endUpdatedDate = addDays(new Date(updatedDate), daysDifference);
      endUpdatedDate.setHours(endHours);
      endUpdatedDate.setMinutes(endMinutes);

      handleEndDateChange(endUpdatedDate);
    }
  };
  const calendarContainerRef = useRef<HTMLDivElement>(null);

  return (
    <Box sx={{ overflow: "auto" }} ref={calendarContainerRef}>
      <InfoCard
        sx={{
          mb: 2,
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Stack
            sx={{
              gap: 1.5,
            }}
          >
            {!enableDateRange && (
              <TimeSelectorDatePicker
                value={startDate}
                onDateChange={handleStartDateChange}
                fullWidth
              />
            )}
            <Stack
              direction={enableDateRange ? "column" : "row"}
              sx={{
                gap: 2,
              }}
            >
              <Stack
                direction="row"
                sx={{
                  gap: 2,
                }}
              >
                {enableDateRange && (
                  <TimeSelectorDatePicker
                    label="Start date"
                    value={startDate}
                    fullWidth={isAllDay}
                    onDateChange={handleStartDateChange}
                  />
                )}
                {!isAllDay && (
                  <TimeSelectorTimePicker
                    value={startDate}
                    label="Start time"
                    onTimeChange={handleStartDateChange}
                  />
                )}
              </Stack>
              {!enableDateRange && (
                <Divider
                  orientation="horizontal"
                  sx={{
                    borderBottomWidth: "2px",
                    alignSelf: "center",
                    width: ".5rem",
                  }}
                />
              )}
              <Stack
                direction="row"
                sx={{
                  gap: 2,
                }}
              >
                {enableDateRange && (
                  <TimeSelectorDatePicker
                    value={endDate}
                    label="End date"
                    fullWidth={isAllDay}
                    onDateChange={(date) => handleEndDateChange(date)}
                  />
                )}
                {!isAllDay && (
                  <TimeSelectorTimePicker
                    value={endDate}
                    label="End time"
                    onTimeChange={(date) =>
                      handleEndDateChange(date, disableBookingOver24Hours)
                    }
                    minTime={
                      isSameDay(startDate, endDate)
                        ? addMinutes(startDate, 1)
                        : undefined
                    }
                  />
                )}
              </Stack>
            </Stack>
          </Stack>
        </LocalizationProvider>
        {slots?.timePickerAdditionalInfo}
      </InfoCard>
      <Typography
        variant="paragraphSmall"
        sx={{
          my: 1,
          color: "text.secondary",
        }}
      >
        Please note: You operate in{" "}
        <strong style={{ color: TEXT_PRIMARY_DARK }}>
          {medspaReadableTimezone}
        </strong>{" "}
        time.
      </Typography>
      <InfoCard sx={{ mt: 2, zIndex: 9999 }}>
        <MoxieFullCalendar
          calendarRef={calendarRef}
          handleCalendarGoToDate={handleCalendarGoToDate}
          height={getCalendarHeight(
            68,
            DEFAULT_CALENDAR_HEADER_HEIGHT,
            DEFAULT_PAGE_PADDING_BOTTOM,
            0
          )}
          initDate={startDate}
          initialView="timeGridDay"
          {...interactiveCalendarProps}
          events={events}
          eventChange={(info) => {
            const { start, end } = info.event;

            if (start && end) {
              handleStartDateChange(start);
              handleEndDateChange(end);
            }
          }}
          businessHours={businessHours}
          calendarContainerRef={calendarContainerRef}
          focusOnTime={startDate}
        />
      </InfoCard>
    </Box>
  );
};

export default forwardRef(TimeSelector);
