import { createTheme, Shadows } from "@mui/material/styles";
import {
  BLACK,
  BLUE,
  ERROR_PRIMARY,
  GREY,
  INFO_PRIMARY,
  MINT,
  PRIMARY,
  SALMON,
  SUCCESS_PRIMARY,
  TEXT_PRIMARY_DARK,
  TEXT_SECONDARY,
  TEXT_DISABLED,
  VIOLET,
  WARNING_PRIMARY,
  WHITE,
  YELLOW,
} from "./colorPalette";
import NextLink from "./nextLink";

const theme = createTheme({
  shadows: Array(25).fill("none") as Shadows,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      "lg-client": 1176, // 1128 + 48 for padding
      lg: 1200,
      xl: 1536,
      mobile: 0,
      "ipad-ver": 600,
      "ipad-hor": 1024,
      desktop: 1366,
    },
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          py: 1,
          textTransform: "none",
          fontWeight: 600,
          fontSize: "16px",
          lineHeight: "24px",
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: "none",
        },
      },
      defaultProps: {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        component: NextLink,
      },
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: NextLink,
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: "12px !important",
          borderColor: GREY[20],
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: 16,
          "&:last-child": {
            paddingBottom: 16,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: "12px !important",
          background: WHITE,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
          lineHeight: "24px",
          fontWeight: "bold",
          textTransform: "unset",
          borderRadius: "16px",
        },
        contained: {
          height: "56px",
          color: WHITE,
          borderRadius: "16px",
        },
        outlined: {
          height: "56px",
          border: "2px solid",
          color: VIOLET[90],
          borderRadius: "16px",
          "&:hover": {
            borderWidth: "2px",
          },
        },
        sizeSmall: {
          fontSize: "0.875rem !important", // required to override default MuI text styles
          fontWeight: "700 !important",
          borderRadius: "12px",
          height: "40px",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
          lineHeight: "24px",
          fontWeight: "normal",
        },
        label: {
          paddingRight: "10px",
          paddingLeft: "10px",
        },
        labelMedium: {
          fontSize: "1rem",
          lineHeight: "24px",
        },
        labelSmall: {
          fontSize: "0.875rem",
          lineHeight: "24px",
          fontWeight: 600,
        },
        sizeSmall: {
          height: 28,
        },
        icon: {
          marginLeft: "10px",
          marginRight: "-6px",
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        thumb: {
          background: WHITE,
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: "h1",
          h2: "h2",
          h3: "h3",
          subtitleDefault: "h3",
          subtitleSmall: "h3",
          paragraph: "p",
          paragraphSmall: "p",
          paragraphTiny: "p",
          labelDefault: "h6",
          labelSmall: "h6",
          labelTiny: "h6",
          labelMicro: "h6",
        },
        variant: "paragraph",
      },
      styleOverrides: {
        root: {
          "& p": {
            margin: 0,
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          boxSizing: "content-box !important" as "content-box",
          width: "24px",
          height: "24px",
          padding: "8px !important",
          margin: "4px -2px 4px 4px",
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          boxSizing: "content-box !important" as "content-box",
          width: "24px",
          height: "24px",
          padding: "8px !important",
          margin: "4px -2px 4px 4px",
        },
      },
    },
  },
  typography: {
    fontFamily: ["Nunito Sans", "sans-serif"].join(", "),
    h1: {
      fontSize: "1.5rem",
      lineHeight: "36px",
      fontWeight: "bold",
    },
    h2: {
      fontSize: "1.25rem",
      lineHeight: "28px",
      fontWeight: "bold",
    },
    h3: {
      fontSize: "1.125rem",
      lineHeight: "24px",
      fontWeight: "bold",
    },
    paragraph: {
      fontSize: "1rem",
      lineHeight: "24px",
      fontWeight: "normal",
    },
    paragraphSmall: {
      fontSize: "0.875rem",
      lineHeight: "20px",
      fontWeight: "normal",
    },
    paragraphTiny: {
      fontSize: "0.75rem",
      lineHeight: "16px",
      fontWeight: "normal",
    },
    subtitleDefault: {
      fontSize: "1rem",
      lineHeight: "24px",
      fontWeight: "bold",
    },
    subtitleSmall: {
      fontSize: "0.875rem",
      lineHeight: "22px",
      fontWeight: "bold",
    },
    buttonDefault: {
      fontSize: "1rem",
      lineHeight: "24px",
      fontWeight: "bold",
    },
    buttonSmall: {
      fontSize: "0.875rem",
      lineHeight: "24px",
      fontWeight: "bold",
    },
    labelDefault: {
      fontSize: "1rem",
      lineHeight: "24px",
      fontWeight: "normal",
    },
    labelSmall: {
      fontSize: "0.875rem",
      lineHeight: "20px",
      fontWeight: 600,
    },
    labelTiny: {
      fontSize: "0.75rem",
      lineHeight: "18px",
      fontWeight: 600,
    },
    labelMicro: {
      fontSize: "0.625rem",
      lineHeight: "16px",
      fontWeight: 600,
    },
  },
  palette: {
    background: {
      default: GREY[5],
      paper: WHITE,
    },
    text: {
      primary: TEXT_PRIMARY_DARK,
      secondary: TEXT_SECONDARY,
      disabled: TEXT_DISABLED,
    },
    secondary: {
      main: GREY[70],
      light: GREY[50],
    },
    third: {
      main: WHITE,
    },
    primary: {
      main: PRIMARY,
    },
    success: {
      main: SUCCESS_PRIMARY,
    },
    error: {
      main: ERROR_PRIMARY,
    },
    warning: {
      main: WARNING_PRIMARY,
    },
    info: {
      main: INFO_PRIMARY,
    },
    grey: {
      900: GREY[90],
      800: GREY[80],
      700: GREY[70],
      600: GREY[60],
      500: GREY[50],
      400: GREY[40],
      300: GREY[30],
      200: GREY[20],
      100: GREY[10],
      50: GREY[5],
    },
    violet: {
      main: VIOLET[100],
      light: VIOLET[40],
      contrastText: VIOLET[100],
    },
    lightGray: {
      main: GREY[20],
      contrastText: BLACK,
      dark: GREY[30],
    },
    gray: {
      light: GREY[40],
      main: GREY[50],
      dark: GREY[60],
      contrastText: WHITE,
    },
    green: {
      main: MINT[40],
      dark: MINT[80],
      contrastText: MINT[100],
    },
    yellow: {
      main: YELLOW[40],
      contrastText: YELLOW[100],
    },
    red: {
      main: SALMON[40],
      contrastText: SALMON[100],
    },
    blue: {
      main: BLUE[20],
      contrastText: BLUE[100],
    },
    lightViolet: {
      main: VIOLET[40],
      contrastText: VIOLET[100],
    },
  },
});
export default theme;
