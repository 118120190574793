import { ChipProps } from "@mui/material";
import { ReactElement } from "react";
import {
  CheckIcon,
  MinusSmallIcon,
  WarningIcon,
} from "@/components/common/icons";
import {
  GREY,
  SUCCESS,
  SUCCESS_PRIMARY,
  WARNING,
  WARNING_PRIMARY,
} from "@/config/mui/colorPalette";

export enum ComplianceTaskStatus {
  Completed = "completed",
  Incomplete = "incomplete",
}

export enum ComplianceTaskUiStatus {
  COMPLETED = "completed",
  INCOMPLETE_REQUIRED = "incomplete_required",
  INCOMPLETE_OPTIONAL = "incomplete_optional",
}

export type ComplianceTask = {
  status?: string;
  required?: boolean;
};

export const getComplianceTaskStatusUI = (task: ComplianceTask) => {
  if (task.status === ComplianceTaskStatus.Completed) {
    return UI_TASK_STATUS_CONFIG[ComplianceTaskUiStatus.COMPLETED];
  }
  if (task.required) {
    return UI_TASK_STATUS_CONFIG[ComplianceTaskUiStatus.INCOMPLETE_REQUIRED];
  }
  return UI_TASK_STATUS_CONFIG[ComplianceTaskUiStatus.INCOMPLETE_OPTIONAL];
};

type TaskStatusConfig = {
  label: string;
  color: string;
  chipColor: NonNullable<ChipProps["color"]>;
  icon: ReactElement<any>;
  tooltip: string;
};

const UI_TASK_STATUS_CONFIG: Record<ComplianceTaskUiStatus, TaskStatusConfig> =
  {
    [ComplianceTaskUiStatus.COMPLETED]: {
      label: "Completed",
      color: SUCCESS[20],
      chipColor: "green",
      icon: <CheckIcon color={SUCCESS_PRIMARY} size="16px" />,
      tooltip: "Completed",
    },
    [ComplianceTaskUiStatus.INCOMPLETE_REQUIRED]: {
      label: "Not completed",
      color: WARNING[20],
      chipColor: "yellow",
      icon: <WarningIcon color={WARNING_PRIMARY} size="16px" />,
      tooltip: "This requirement is incomplete",
    },
    [ComplianceTaskUiStatus.INCOMPLETE_OPTIONAL]: {
      label: "Not required",
      color: GREY[15],
      chipColor: "default",
      icon: <MinusSmallIcon color={GREY[50]} size="16px" />,
      tooltip: "This requirement is optional",
    },
  };

export const isComplianceTaskIncomplete = (task: ComplianceTask) =>
  Boolean(task?.status === ComplianceTaskStatus.Incomplete && task?.required);

export const isComplianceTaskCompleted = (task: ComplianceTask) =>
  Boolean(task?.status === ComplianceTaskStatus.Completed);
