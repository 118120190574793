import { useMemo } from "react";
import { DatePeriods } from "@/components/common/filters/dateFilter/dateDialog";
import useApptHistoryTableData from "@/hooks/complianceHub/useApptHistoryTableData";
import useComplianceTasksData from "@/hooks/complianceHub/useComplianceTasksData";
import useProtocolsAndStandingOrdersTableData from "@/hooks/complianceHub/useProtocolsAndStandingOrdersTableData";
import useServiceDocumentsTableData from "@/hooks/complianceHub/useServiceDocumentsTableData";
import { useIsProviderOwner } from "@/hooks/user/useIsProviderOwner";
import { getTimeRange } from "@/store/views/globalFilters/dateFilterStore";

const POLL_INTERVAL_MS = 300000; // 5 minutes

// Make sure issues from all compliance hub tables/views are summed up here
// When a new section's issue count is included here it should be put behind a corresponding feature flag

export default function useComplianceHubIssueCount() {
  const { isProviderOwner } = useIsProviderOwner();

  const { issues: serviceDocumentsIssueCount = 0 } =
    useServiceDocumentsTableData({
      skip: !isProviderOwner,
      pollInterval: POLL_INTERVAL_MS,
    });

  const { issues: protocolsAndStandingOrdersIssues = 0 } =
    useProtocolsAndStandingOrdersTableData({
      skip: !isProviderOwner,
      pollInterval: POLL_INTERVAL_MS,
    });

  const { startDate, endDate } = useMemo(
    () => getTimeRange(DatePeriods.LAST_30_DAYS),
    []
  );

  const { issues: appointmentHistoryIssues = 0 } = useApptHistoryTableData({
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
    queryOptions: {
      pollInterval: POLL_INTERVAL_MS,
    },
  });

  const { incompleteTasksCount } = useComplianceTasksData({
    pollInterval: POLL_INTERVAL_MS,
  });

  const issuesSum =
    appointmentHistoryIssues +
    protocolsAndStandingOrdersIssues +
    serviceDocumentsIssueCount +
    incompleteTasksCount;

  return issuesSum;
}
