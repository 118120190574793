import { differenceInDays, parseISO } from "date-fns";
import { ClientCherryApplicationRecordStatus } from "@/types";

export enum CherryApplicationStatus {
  EXPIRED = "expired",
  EXPIRING_SOON = "expiring_soon",
  IN_PROGRESS = "in_progress",
  APPROVED = "approved",
  NOT_APPROVED = "not_approved",
  NOT_INTERESTED = "not_interested",
  HAS_NOT_APPLIED = "has_not_applied",
}

export function getCherryApplicationStatus(
  status: ClientCherryApplicationRecordStatus | undefined,
  expiresAt: string | undefined,
  interestedInPaymentPlans: boolean | null
) {
  switch (status) {
    case undefined:
      if (interestedInPaymentPlans === false) {
        return CherryApplicationStatus.NOT_INTERESTED;
      }
      return CherryApplicationStatus.HAS_NOT_APPLIED;
    case ClientCherryApplicationRecordStatus.APPROVED:
      if (expiresAt && differenceInDays(parseISO(expiresAt), new Date()) < 7) {
        return CherryApplicationStatus.EXPIRING_SOON;
      }
      return CherryApplicationStatus.APPROVED;
    case ClientCherryApplicationRecordStatus.NOT_APPROVED:
      return CherryApplicationStatus.NOT_APPROVED;
    case ClientCherryApplicationRecordStatus.PENDING:
      return CherryApplicationStatus.IN_PROGRESS;
    case ClientCherryApplicationRecordStatus.EXPIRED:
      return CherryApplicationStatus.EXPIRED;
    default:
      return status satisfies never;
  }
}
