import { datadogLogs } from "@datadog/browser-logs";
import { useCallback } from "react";
import { isCriticalWorkflow } from "./observability/criticalWorkflows";

// noinspection JSCommentMatchesSignature
/**
 * Hook that returns a memoized error logging function.
 * @param {Error | string | unknown} error - The error object or value to log. Other values will be wrapped in Error instances.
 * @param {string} [message] - Optional custom message. If not provided, uses error.message.
 * @param {object} [context] - Optional context object to include in the log.
 * @returns {void}
 */
export default function useErrorLogger() {
  return useCallback(
    (error: Error | string | unknown, message?: string, context?: object) => {
      let errorInstance: Error;

      if (isCriticalWorkflow()) {
        context = { ...context, critical_workflow: true };
      }

      if (error instanceof Error) {
        errorInstance = error;
      } else {
        const message =
          typeof error == "string" ? error : `Unknown error: ${error}`;
        errorInstance = new Error(message);
      }

      message = message || errorInstance.message;
      return datadogLogs.logger.error(message, context, errorInstance);
    },
    []
  );
}
