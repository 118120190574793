import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { GetToken } from "@clerk/types";
import { createClient } from "graphql-ws";
import { CLERK_JWT_HASURA_TEMPLATE } from "@/types";
import { getGraphqlURI } from "./apollo.utils";

const wsClient = (getClerkToken: GetToken) => {
  let timedOut: NodeJS.Timeout | null;

  const client = createClient({
    url: getGraphqlURI().replace("http", "ws"),
    keepAlive: 5_000,
    shouldRetry: () => true, // Retry on connection errors, Sarafi browser does not support keepAlive as you would expect
    on: {
      // Close connection if server does not respond to keepAlive (user locks thescreen on an iOS device) - https://the-guild.dev/graphql/ws/recipes#client-usage-with-abrupt-termination-on-pong-timeout
      ping: (received) => {
        if (!received /* sent */) {
          timedOut = setTimeout(() => {
            // a close event `4499: Terminated` is issued to the current WebSocket and an
            // artificial `{ code: 4499, reason: 'Terminated', wasClean: false }` close-event-like
            // object is immediately emitted without waiting for the one coming from `WebSocket.onclose`
            //
            // calling terminate is not considered fatal and a connection retry will occur as expected
            //
            // see: https://github.com/enisdenjo/graphql-ws/discussions/290
            client.terminate();
          }, 5_000);
        }
      },
      pong: (received) => {
        if (received) {
          clearTimeout(timedOut);
        }
      },
    },
    connectionParams: async () => ({
      headers: {
        Authorization: `Bearer ${await getClerkToken(
          CLERK_JWT_HASURA_TEMPLATE
        )}`,
      },
    }),
  });

  return client;
};

export const wsLink = (getClerkToken: GetToken) => {
  return new GraphQLWsLink(wsClient(getClerkToken));
};
