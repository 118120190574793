import { Box, CircularProgress } from "@mui/material";
import useMoxieMediaQuery from "@/hooks/misc/useMoxieMediaQuery";

export default function FetchMoreLoader() {
  const isBigScreen = useMoxieMediaQuery("ipad-hor");

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        py: isBigScreen ? 3 : 5,
      }}
    >
      <CircularProgress color="primary" />
    </Box>
  );
}
