import { addMinutes, differenceInMinutes } from "date-fns";
import { useRouter } from "next/router";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useUser } from "@/auth/useUser";
import { useCreateAppointmentMutation } from "@/graphql/mutations/createAppointment.graphql.types";
import {
  AvailableResourcesQuery,
  useAvailableResourcesQuery,
} from "@/graphql/queries/availableResources.graphql.types";
import {
  ClientForVisitCreationQuery,
  useClientForVisitCreationQuery,
} from "@/graphql/queries/clients.graphql.types";
import {
  MedspaProvidersQuery,
  useMedspaProvidersQuery,
} from "@/graphql/queries/medspaProviders.graphql.types";
import { useServiceMenuItemsBasicInfoQuery } from "@/graphql/queries/serviceMenuItems.graphql.types";
import { useProviderBusinessHours } from "@/hooks/common/useBusinessHours";
import useMedspaTimezone from "@/hooks/common/useMedspaTimezone";
import { TelehealthType } from "@/types";
import { useDisabledServiceIds, useEligibleProviders } from "@/utils/booking";
import { getTelehealthTypeSchema } from "@/utils/telehealth";
import { getDurationFromServices } from "@/utils/visit";
import { useCreateVisitDefaultDate } from "../dates/useCreateVisitDefaultDate";
import useCreateViewCalendarEvents from "./useCreateViewCalendarEvents";

export type ClientForVisitCreation = NonNullable<
  ClientForVisitCreationQuery["clientByPk"]
>;

export type Provider =
  MedspaProvidersQuery["medspaByPk"]["userMedspas"][number];

export const CLIENT_SELECTION = "CLIENT_SELECTION";
export const SERVICES_SELECTION = "SERVICES_SELECTION";
export const PROVIDER_SELECTION = "PROVIDER_SELECTION";
export const TIME_SELECTION = "TIME_SELECTION";

export type VisitCreationStep =
  | typeof CLIENT_SELECTION
  | typeof SERVICES_SELECTION
  | typeof PROVIDER_SELECTION
  | typeof TIME_SELECTION;

export type Resource = AvailableResourcesQuery["availableResources"];

export default function useCreateVisit() {
  const {
    query: { clientId },
    isReady,
  } = useRouter();
  const { zonedTimeToUtcIso, medspaZonedTimeToUtc } = useMedspaTimezone();
  const defaultDate = useCreateVisitDefaultDate();

  const { medspa, user } = useUser();
  const { data: clientData } = useClientForVisitCreationQuery({
    variables: {
      clientId: clientId as string,
    },
    skip: !clientId,
  });

  const { data: providersData } = useMedspaProvidersQuery({
    variables: {
      id: medspa,
    },
    skip: !medspa,
  });
  const providers = useMemo(
    () => providersData?.medspaByPk?.userMedspas || [],
    [providersData]
  );

  const [step, setStep] = useState<VisitCreationStep>(null);
  const [client, setClient] = useState<ClientForVisitCreation>(null);
  const [services, setServices] = useState<string[]>([]);
  const [provider, setProvider] = useState<Provider>(null);
  const [start, setStart] = useState<Date | null>(defaultDate);
  const [end, setEnd] = useState<Date | null>(() => {
    return addMinutes(start, 1);
  });

  const [previousProvider, setPreviousProvider] = useState<Provider | null>(
    null
  );

  const [telehealthType, setTelehealthType] = useState<
    TelehealthType | undefined
  >(undefined);
  const [telehealthVideoLink, setTelehealthVideoLink] = useState<
    string | undefined
  >(undefined);
  const [telehealthAdditionalDetails, setTelehealthAdditionalDetails] =
    useState<string | undefined>(undefined);

  const [isTelehealthVisitDetailsValid, setIsTelehealthVisitDetailsValid] =
    useState<boolean>(false); // managed by form component, has to be accessible by top level component

  const { data: availableServices } = useServiceMenuItemsBasicInfoQuery({
    variables: {
      medspaId: medspa,
      excludeVariablePricing: true,
    },
    fetchPolicy: "cache-and-network",
    skip: !medspa,
  });

  const { data: availableResources } = useAvailableResourcesQuery({
    variables: {
      medspaId: +medspa,
      services: services.map((service) => +service),
      visitStart: medspaZonedTimeToUtc(start).toISOString(),
      visitEnd: medspaZonedTimeToUtc(end).toISOString(),
    },
    fetchPolicy: "cache-and-network",
    skip: !medspa || !services.length || !start || !end,
  });

  const providerBusinessHours = useProviderBusinessHours(provider?.id);

  const [createAppointmentMutation] = useCreateAppointmentMutation();

  useEffect(() => {
    if (!isReady) return;
    if (clientId && clientData && clientData.clientByPk) {
      setStep(SERVICES_SELECTION);
      setClient(clientData.clientByPk);
    }
    if (!clientId || clientData?.clientByPk === null) {
      setStep(CLIENT_SELECTION);
    }
  }, [isReady, clientId, clientData]);

  const eligibleProviders = useEligibleProviders(
    services,
    providers,
    availableServices?.medspaServiceMenuItem
  );
  const hasMultipleProviders = eligibleProviders.length > 1;

  const disabledServiceIds = useDisabledServiceIds(
    eligibleProviders,
    availableServices?.medspaServiceMenuItem
  );

  const checkProviderEligibility = useCallback(
    (provider: Provider): boolean => {
      return (
        eligibleProviders.length > 0 &&
        eligibleProviders.some((p) => p.id === provider?.id)
      );
    },
    [eligibleProviders]
  );

  useEffect(() => {
    if (provider && checkProviderEligibility(provider)) return; // needed for rebooking provider initialization to work

    if (checkProviderEligibility(previousProvider)) {
      setProvider(previousProvider);
    } else if (user && eligibleProviders.length > 0) {
      const defaultProvider =
        eligibleProviders.find(
          (userMedspa) => userMedspa.user.id === user.id
        ) || eligibleProviders[0];

      setProvider(defaultProvider);
    }
  }, [previousProvider, checkProviderEligibility, eligibleProviders, user]);

  const servicesDuration = useMemo(() => {
    return availableServices
      ? getDurationFromServices(
          services,
          availableServices.medspaServiceMenuItem
        )
      : 0;
  }, [services, availableServices]);

  const totalVisitDuration = useMemo(() => {
    return differenceInMinutes(end, start);
  }, [end, start]);

  const servicesLabel = useMemo(
    () =>
      availableServices?.medspaServiceMenuItem
        .filter((s) => services.includes(s.id))
        .map((s) => s.name)
        .join(" • "),
    [availableServices, services]
  );

  const handleServicesSelection = useCallback(
    (servicesId: string[], startDate?: Date) => {
      const previouslySelectedProvider = provider;
      setProvider(null);

      setServices(servicesId);

      const servicesDuration = availableServices
        ? getDurationFromServices(
            servicesId,
            availableServices.medspaServiceMenuItem
          )
        : 0;

      // In the new booking flow, the start date is already adjusted to the medspa timezone
      // We still use the old flow for mobile and different views, but this can be simplified
      // once the old flow is completely removed
      const start = startDate || defaultDate;

      setEnd(addMinutes(start, servicesDuration));
      setStart(start);

      setPreviousProvider(previouslySelectedProvider);

      if (hasMultipleProviders) {
        setStep(PROVIDER_SELECTION);
        return;
      }
      setProvider(eligibleProviders[0]);
      setStep(TIME_SELECTION);
    },
    [
      provider,
      defaultDate,
      availableServices,
      eligibleProviders,
      hasMultipleProviders,
    ]
  );

  const calendarEvents = useCreateViewCalendarEvents(
    start,
    end,
    provider,
    availableServices,
    client,
    "",
    servicesLabel
  );

  const handleCreateVisit = () => {
    return createAppointmentMutation({
      variables: {
        services,
        startTime: zonedTimeToUtcIso(start),
        endTime: zonedTimeToUtcIso(end),
        clientId: client.id,
        providerId: provider.id,
        visitType: getTelehealthTypeSchema(telehealthType),
        videoLink:
          telehealthType === TelehealthType.PHONE
            ? undefined
            : telehealthVideoLink,
        visitDetails: telehealthAdditionalDetails,
      },
      refetchQueries: [
        "VisitsAndAvailabilityEventsWithDateRange",
        "VisitsAndTimeBlocksWithDateRange",
      ],
    });
  };

  const handleSetStart = (date: Date) => {
    const visitDuration = totalVisitDuration; // save the value before changing time
    setStart(date);
    setEnd(addMinutes(date, visitDuration));
  };

  return {
    step,
    setStep,
    client,
    setClient,
    services,
    setServices,
    handleServicesSelection,
    disabledServiceIds,
    start,
    setStart: handleSetStart,
    end,
    setEnd,
    calendarEvents,
    totalVisitDuration,
    servicesDuration,
    handleCreateVisit,
    provider,
    providers: eligibleProviders,
    setProvider,
    hasMultipleProviders,
    providerBusinessHours,
    resources: availableResources?.availableResources,
    telehealthType,
    setTelehealthType,
    telehealthVideoLink,
    setTelehealthVideoLink,
    telehealthAdditionalDetails,
    setTelehealthAdditionalDetails,
    isTelehealthVisitDetailsValid,
    setIsTelehealthVisitDetailsValid,
  };
}
