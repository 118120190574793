import { Box } from "@mui/material";
import { useEffect } from "react";
import { PRIMARY } from "@/config/mui/colorPalette";
import { useMessagesContext } from "@/contexts/messagesContext";
import useFeatureFlags from "@/hooks/common/useFeatureFlags";

type Props = { floating?: boolean };

export default function MessagesNotificationsDot({ floating = true }: Props) {
  const {
    conversations,
    lastMessageSid,
    calculateNotificationsCount,
    notificationsCount,
    highlevelUnreadMessagesCount,
  } = useMessagesContext();

  const { marketingMessagesV1Enabled } = useFeatureFlags();

  useEffect(() => {
    if (conversations.length) {
      calculateNotificationsCount();
    }
  }, [conversations, lastMessageSid, calculateNotificationsCount]);

  useEffect(() => {
    window.addEventListener("focus", calculateNotificationsCount);
    return () =>
      window.removeEventListener("focus", calculateNotificationsCount);
  }, [calculateNotificationsCount]);

  // If there are no notifications and no unread messages, don't show the counter
  // The icon is shown in the parent component
  // It also disable the counter if the feature flag is not enabled, but we have only highlevelUnreadMessagesCount
  if (
    !notificationsCount &&
    (!marketingMessagesV1Enabled || !highlevelUnreadMessagesCount)
  )
    return;

  let totalUnreadMessages = 0;

  if (marketingMessagesV1Enabled) {
    totalUnreadMessages = notificationsCount + highlevelUnreadMessagesCount;
  } else {
    totalUnreadMessages = notificationsCount;
  }

  const floatingStyles = {
    position: "absolute",
    top: -5,
    right: -5,
  };

  return (
    <Box
      sx={{
        ...(floating ? floatingStyles : {}),
        width: "16px",
        height: "16px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "50%",
        bgcolor: PRIMARY,
        color: "white",
        typography: "labelTiny",
      }}
    >
      {totalUnreadMessages}
    </Box>
  );
}
