import { useMemo } from "react";
import toast from "react-hot-toast";
import { FeaturePermission } from "@/__generated__/featurePermissions";
import { useUser } from "@/auth/useUser";
import {
  CalendarIcon,
  CalendarUserIcon,
  CapsuleIcon,
  CardiologyIcon,
  CartIcon,
  CoinIcon,
  DocumentIcon,
  MedsIcon,
  MessageIcon,
  PackageIcon,
  PinpaperFilledIcon,
  ReportsIcon,
  SettingsIcon,
  ShareIcon,
  ShieldCheckIcon,
  UserQuestionIcon,
  UsersIcon,
  UserWarningIcon,
  WarningCircleIcon,
} from "@/components/common/icons";
import MoneyIcon from "@/components/common/icons/moneyIcon";
import ComplianceHubIssueCounterNotificationDot from "@/components/common/navigation/complianceHubIssueCounterNotificationDot";
import { OverdueChartsNotificationsDot } from "@/components/common/navigation/overdueChartsNotificationsDot";
import PendingAdverseReactionsNotificationDot from "@/components/common/navigation/pendingAdverseReactionsNotificationDot";
import { MOXIE_HELP_DESK_URL, MOXIE_SHOPIFY_STORE_URL } from "@/config";
import { useMedspaId } from "@/hooks/common/useMedspaId";
import { Role, Roles } from "@/types";
import useFeatureFlags from "../common/useFeatureFlags";
import useMoxieBalancePermissions from "../common/useMoxieBalancePermissions";
import { useIsEligibleForSelfReviewGfe } from "../user/useIsEligibleForSelfReviewGfe";
import { useIsGfeReviewer } from "../user/useIsGfeReviewer";

type NavItem = {
  title: string;
  path?: string;
  getIsActive?: (url: string) => boolean;
  onClick?: () => void;
  icon: React.ElementType;
  isExternal?: boolean;
  notifications?: React.ElementType;
  target?: string;
};

export type NavDataType = {
  subheader?: string;
  items: NavItem[];
}[];

const DedicatedSettingsItem = {
  SERVICES_AND_PRODUCTS: "services-and-products",
  CLINICAL_DOCUMENTS: "clinical-documents",
  BILLING: "billing",
};

const filterForVisibleItems = (navData: NavDataType): NavDataType =>
  navData.map((group) => ({
    ...group,
    items: group.items.filter(Boolean),
  }));

export default function useNavigationData(role: Role, onCloseNav: () => void) {
  const {
    userMedspa,
    hasUrlPermission,
    hasFeaturePermission,
    newPermissionsEnabledForUser,
  } = useUser();
  const medspa = useMedspaId();

  const {
    balanceEnabled,
    orderPrescriptionDrawerV1Enabled,
    commandAiEnabled,
    shopifyPlusEnabled,
    reportABugVerticalNavEnabled,
    payrollIntegrationV1Enabled,
  } = useFeatureFlags();
  const isEligibleForSelfGfeReview = useIsEligibleForSelfReviewGfe();
  const isProviderOwner = userMedspa?.isProviderOwner;
  const isGfeReviewer = useIsGfeReviewer();
  const { showBalance } = useMoxieBalancePermissions();

  const navData = useMemo(() => {
    const ReviewerGfeItem: NavItem = {
      title: "GFEs",
      path: `/${medspa}/review/gfes`,
      getIsActive: (url) =>
        url.includes("review/gfe") || url.includes("review/sync-gfe"),
      icon: CardiologyIcon,
    };

    const ComplianceHubItem: NavItem = {
      title: "Compliance Hub",
      path: `/${medspa}/compliance-hub`,
      icon: ShieldCheckIcon,
      notifications: ComplianceHubIssueCounterNotificationDot,
    };

    const SettingsItem: NavItem = {
      title: "Settings",
      path: `/${medspa}/settings`,
      getIsActive: (url) => {
        const isDedicatedItem = Object.values(DedicatedSettingsItem).some(
          (item) => url.includes(item)
        );

        return isDedicatedItem ? false : url.includes("settings");
      },
      icon: SettingsIcon,
    };

    const ClientsItem: NavItem = {
      title: "Clients",
      path: `/${medspa}/clients`,
      icon: UsersIcon,
    };

    const ScheduleItem: NavItem = {
      title: "Schedule",
      path: `/${medspa}/visits`,
      icon: CalendarIcon,
    };

    const MessagesItem: NavItem = {
      title: "Messages",
      path: `/${medspa}/messages`,
      icon: MessageIcon,
    };

    const MedSpaChartsItem: NavItem = {
      title: "Charts",
      path: `/${medspa}/charts`,
      icon: PinpaperFilledIcon,
      notifications: OverdueChartsNotificationsDot,
    };

    const ChartsReviewItem: NavItem = {
      title: "Charts",
      path: `/${medspa}/review/charts`,
      icon: PinpaperFilledIcon,
      getIsActive: (url) =>
        url.includes(`/review/charts`) ||
        /\/review-visits\/\d+\/chart/.test(url), // match /review-visits/:id/chart
    };

    const InventoryItem: NavItem = {
      title: "Inventory",
      path: `/${medspa}/inventory`,
      icon: PackageIcon,
    };

    const BalanceItem: NavItem = {
      title: "Balance",
      path: `/${medspa}/balance`,
      icon: CoinIcon,
    };

    const SuppliesItem: NavItem = {
      title: "Supplies",
      path: shopifyPlusEnabled
        ? MOXIE_SHOPIFY_STORE_URL
        : `/${medspa}/supplies`,
      icon: CartIcon,
      isExternal: shopifyPlusEnabled,
      target: shopifyPlusEnabled ? "_blank" : undefined,
    };

    const MarketingItem: NavItem = {
      title: "Marketing",
      path: `/${medspa}/marketing/content-vault`,
      icon: ShareIcon,
    };

    const BookkeepingItem: NavItem = {
      title: "Bookkeeping",
      path: `/${medspa}/bookkeeping`,
      icon: MoneyIcon,
    };

    const ReportsItem: NavItem = {
      title: "Reports",
      path: `/${medspa}/reports`,
      icon: ReportsIcon,
    };

    const ServicesProductsItem: NavItem = {
      title: "Services & Products",
      path: `/${medspa}/settings/${DedicatedSettingsItem.SERVICES_AND_PRODUCTS}`,
      icon: MedsIcon,
    };

    const ClinicalDocumentsItem: NavItem = {
      title: "Clinical Documents",
      path: `/${medspa}/settings/medical-director/${DedicatedSettingsItem.CLINICAL_DOCUMENTS}`,
      icon: DocumentIcon,
    };

    const ProviderMeetingsItem: NavItem = {
      title: "Provider Meetings",
      path: `/${medspa}/provider-meetings`,
      icon: CalendarUserIcon,
    };

    const AdverseReactionsItem: NavItem = {
      title: "Adverse reactions",
      path: `/${medspa}/adverse-reactions`,
      icon: UserWarningIcon,
      notifications: PendingAdverseReactionsNotificationDot,
    };

    const BillingItem: NavItem = {
      title: "Billing",
      path: `/${medspa}/settings/medical-director/${DedicatedSettingsItem.BILLING}`,
      icon: CoinIcon,
    };

    const ScriptsItem: NavItem = {
      title: "Scripts",
      path: `/${medspa}/scripts`,
      icon: CapsuleIcon,
    };

    const HelpDeskItems: NavItem[] = [
      // New Help Desk button, powered by CommandAi HelpHub
      commandAiEnabled && {
        title: "Help Desk",
        onClick: () => {
          if (window.CommandBar) {
            window.CommandBar.toggleHelpHub();
            onCloseNav();
          } else {
            toast.error(
              "Help Desk is not compatible with this browser. Please try another device or reach out to your PSM for assistance."
            );
          }
        },
        icon: UserQuestionIcon,
      },
      // Redirect to old Help Desk, powered by public Hubspot Knowledge Base
      !commandAiEnabled && {
        title: "Help Desk",
        path: MOXIE_HELP_DESK_URL,
        isExternal: true,
        icon: UserQuestionIcon,
      },
    ];

    const BugReportItem: NavItem = {
      title: "Report a Bug",
      onClick: () => {
        window.birdeatsbug.trigger();
        onCloseNav();
      },
      icon: WarningCircleIcon,
    };

    // TODO-payroll: ideally this should be hidden for users that don't have payroll initialized (are not employee or contractor)
    const PayrollItem: NavItem = {
      title: "Payroll",
      path: `/${medspa}/payroll`,
      icon: CoinIcon,
    };

    const navDataProvider: NavDataType = [
      {
        items: [
          ScheduleItem,
          ClientsItem,
          MessagesItem,
          MedSpaChartsItem,
          orderPrescriptionDrawerV1Enabled && ScriptsItem,
          isEligibleForSelfGfeReview && ReviewerGfeItem,
          InventoryItem,
          // Must be provider owner or PC owner to see balance page + feature flag enabled
          balanceEnabled && showBalance && BalanceItem,
          isProviderOwner && SuppliesItem,
          MarketingItem,
          isProviderOwner && BookkeepingItem,
          isProviderOwner && ReportsItem,
          ServicesProductsItem,
          ComplianceHubItem,
          SettingsItem,
          ...HelpDeskItems,
          reportABugVerticalNavEnabled && BugReportItem,
          payrollIntegrationV1Enabled && PayrollItem,
        ],
      },
    ];

    const navDataMD: NavDataType = [
      {
        items: [
          ReviewerGfeItem,
          ChartsReviewItem,
          ClinicalDocumentsItem,
          ProviderMeetingsItem,
          ClientsItem,
          AdverseReactionsItem,
          BillingItem,
          // Must be provider owner or PC owner to see balance page + feature flag enabled
          balanceEnabled && showBalance && BalanceItem,
          ComplianceHubItem,
          SettingsItem,
          ...HelpDeskItems,
          reportABugVerticalNavEnabled && BugReportItem,
        ],
      },
    ];

    const navDataGFEReviewer: NavDataType = [
      {
        items: [
          ReviewerGfeItem,
          SettingsItem,
          ...HelpDeskItems,
          reportABugVerticalNavEnabled && BugReportItem,
        ],
      },
    ];

    const restrictedNavDataItems: NavItem[] = [
      ScheduleItem,
      ClientsItem,
      MessagesItem,
      MedSpaChartsItem,
      (role === Roles.MEDICAL_DIRECTOR || isEligibleForSelfGfeReview) &&
        ReviewerGfeItem,
      InventoryItem,
      ChartsReviewItem,
      ClinicalDocumentsItem,
      ProviderMeetingsItem,
      AdverseReactionsItem,
      BillingItem,
      orderPrescriptionDrawerV1Enabled && ScriptsItem,
      // Must be provider owner or PC owner to see balance page + feature flag enabled
      balanceEnabled && showBalance && BalanceItem,
      SuppliesItem,
      MarketingItem,
      BookkeepingItem,
      ReportsItem,
      ServicesProductsItem,
      ComplianceHubItem,
      SettingsItem,
      payrollIntegrationV1Enabled && PayrollItem,
    ].filter(Boolean);

    const commonNavDataItems: NavItem[] = [
      reportABugVerticalNavEnabled && BugReportItem,
    ].filter(Boolean);

    const navData: NavDataType = [
      {
        items: [
          ...restrictedNavDataItems.filter((item) =>
            hasUrlPermission(item?.path)
          ),
          ...(hasFeaturePermission(FeaturePermission.VIEW_HELP_DESK)
            ? HelpDeskItems
            : []),
          ...commonNavDataItems,
        ],
      },
    ];

    const data = {
      [Roles.PROVIDER]: navDataProvider,
      [Roles.MEDICAL_DIRECTOR]: isGfeReviewer ? navDataGFEReviewer : navDataMD,
    };

    return filterForVisibleItems(
      newPermissionsEnabledForUser ? navData : data[role] || []
    );
  }, [
    medspa,
    shopifyPlusEnabled,
    commandAiEnabled,
    orderPrescriptionDrawerV1Enabled,
    isEligibleForSelfGfeReview,
    balanceEnabled,
    showBalance,
    isProviderOwner,
    reportABugVerticalNavEnabled,
    payrollIntegrationV1Enabled,
    role,
    hasFeaturePermission,
    isGfeReviewer,
    newPermissionsEnabledForUser,
    onCloseNav,
    hasUrlPermission,
  ]);

  return { navData };
}
