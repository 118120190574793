import { FeaturePermission } from "@/__generated__/featurePermissions";
import { useUser } from "@/auth/useUser";
import { useIsProviderOwner } from "@/hooks/user/useIsProviderOwner";

export const useSuppliesSavings = (): {
  monthlySavings: number;
  totalSavings: number;
  onReportClick?: () => void;
} => {
  // todo: fetch data from backend, skip for non-providers,
  // make sure this is not called on each page change (cache-only policy)
  const { newPermissionsEnabledForUser, hasFeaturePermission } = useUser();
  const isProviderOwner = useIsProviderOwner();
  const canViewSuppliesReport = newPermissionsEnabledForUser
    ? hasFeaturePermission(FeaturePermission.VIEW_SUPPLIES_REPORT)
    : isProviderOwner;

  const providerOwnerOptions = {
    onReportClick: () => {
      window.open("https://www.google.com/", "_blank");
    },
  };

  return {
    monthlySavings: 254.67,
    totalSavings: 5323.12,
    ...(canViewSuppliesReport && providerOwnerOptions),
  };
};
