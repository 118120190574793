import { addHours, isAfter, isBefore } from "date-fns";
import { useRouter } from "next/router";
import { useMemo } from "react";
import toast from "react-hot-toast";
import { FeaturePermission } from "@/__generated__/featurePermissions";
import { useUser } from "@/auth/useUser";
import { useConfirm } from "@/components/providers/confirmProvider";
import { APP_URL_BASE } from "@/config";
import { useCreateShortUrlMutation } from "@/graphql/mutations/createShortUrl.graphql.types";
import { useCategoriesWithClientIndicationsQuery } from "@/graphql/queries/categories.graphql.types";
import { useClientsCardsOnFileLazyQuery } from "@/graphql/queries/clientsCardsOnFile.graphql.types";
import { useMedspaConfigurationInfoQuery } from "@/graphql/queries/medspaConfigurationInfo.graphql.types";
import { useProviderInfoQuery } from "@/graphql/queries/user.graphql.types";
import useMedspaTimezone from "@/hooks/common/useMedspaTimezone";
import { ClientForVisitCreation } from "@/hooks/visits/useCreateVisit";
import { MEDICAL_DIRECTOR, PROVIDER } from "@/types";
import { hasDjangoMutationError } from "@/utils/djangoMutationError";
import useErrorLogger from "@/utils/useErrorLogger";

export default function useBookingWithPaymentLink(
  start: Date,
  end: Date,
  client: ClientForVisitCreation,
  providerId: string,
  services: string[],
  handleCreateVisit: () => Promise<void>,
  closeDrawer?: () => void
) {
  const { push } = useRouter();
  const { medspa, hasFeaturePermission, providerUserMedspa } = useUser();
  const { data: providerInfoData } = useProviderInfoQuery({
    variables: { id: providerUserMedspa?.user.id },
    skip: !providerUserMedspa?.user.id,
  });

  const providerIsEligibleForCopyCollectCcLink = useMemo(() => {
    return providerInfoData?.userMedspa[0]?.isEligibleForCopyCollectCcLink;
  }, [providerInfoData]);

  const logError = useErrorLogger();
  const [getClientsCardOnFile] = useClientsCardsOnFileLazyQuery();
  const { getConfirm } = useConfirm();
  const { utcToMedspaZonedTime, zonedTimeToUtcIso } = useMedspaTimezone();

  const [createShortUrl] = useCreateShortUrlMutation();

  const hasRequiredBookingPermissions = hasFeaturePermission(
    FeaturePermission.VIEW_FRONT_DESK_BOOKING_INFO,
    [PROVIDER, MEDICAL_DIRECTOR]
  );

  const skipMedspaConfigurationData =
    !providerIsEligibleForCopyCollectCcLink && !hasRequiredBookingPermissions;

  const { data: medspaConfigurationData } = useMedspaConfigurationInfoQuery({
    variables: {
      medspaId: medspa,
    },
    skip: !medspa || skipMedspaConfigurationData,
  });

  const skipCategoriesWithClientIndications = !hasFeaturePermission(
    FeaturePermission.VIEW_CATEGORIES_WITH_CLIENT_INDICATIONS,
    [PROVIDER, MEDICAL_DIRECTOR]
  );
  const { data: categoriesData } = useCategoriesWithClientIndicationsQuery({
    variables: {
      medspaId: medspa,
      clientId: client?.id,
    },
    skip: !medspa || skipCategoriesWithClientIndications || !client,
    fetchPolicy: "cache-and-network",
  });

  const getClientsCreditCard = async (clientId: string) => {
    try {
      const { data } = await getClientsCardOnFile({
        variables: {
          clientId,
        },
      });

      return data.clientByPk.stripeData?.stripeCardsList?.[0];
    } catch (e) {
      logError(e, "Unexpected error while getting client's card on file");
      return null;
    }
  };

  const generateShortLink = async (fullUrl: string) => {
    try {
      const { data } = await createShortUrl({ variables: { fullUrl } });
      const uuid = data?.createShortMoxieUrl.uuid;
      const shortUrl = `${APP_URL_BASE}/url/${uuid}`;
      return shortUrl;
    } catch (e) {
      logError(e);
      toast.error(
        hasDjangoMutationError(e)
          ? e.message
          : `There was a problem with creating the URL`
      );
    }
  };

  const checkIfAllServicesAreBookableOnline = () => {
    const onlineBookableServices = categoriesData?.serviceCategory
      .flatMap((sc) => sc.medspaServiceMenuItems)
      .filter((s) => s.isOnlineBookingEnabled)
      .map((s) => s.id);

    const hasInvalidServices = services.some(
      (s) => !onlineBookableServices.includes(s)
    );

    if (!hasInvalidServices) return true;

    toast.error(
      "This service cannot be booked online. Providers can update this service in their Moxie Suite settings."
    );
    return false;
  };

  const createBookingWithPaymentLink = async () => {
    if (!medspaConfigurationData || !medspaConfigurationData.medspaByPk) {
      toast.error("Unexpected error while generating the payment link");
      logError(
        "Couldn't fetch medspaConfigurationData before generating payment link"
      );
      return;
    }

    if (!checkIfAllServicesAreBookableOnline()) return;

    const { slug, configuration } = medspaConfigurationData.medspaByPk;
    const { firstName, lastName, email, phone, id } = client;

    if (!email || !phone) {
      toast.error("Client's email and phone are required");
      return;
    }

    const minBookingDate = utcToMedspaZonedTime(
      addHours(new Date(), configuration.minimumBookingNoticeHours)
    );
    const maxBookingDate = utcToMedspaZonedTime(
      addHours(new Date(), configuration.maximumAdvanceBookingHours)
    );

    if (isBefore(start, minBookingDate)) {
      toast.error(
        `This medspa allows booking at least ${configuration.minimumBookingNoticeHours} hours in advance`
      );
      return;
    }

    if (isAfter(start, maxBookingDate)) {
      toast.error(
        `This medspa allows booking at most ${configuration.maximumAdvanceBookingHours} hours in advance`
      );
      return;
    }

    const cardOnFile = await getClientsCreditCard(id);

    if (cardOnFile) {
      const { brand, last4, expMonth, expYear } = cardOnFile;
      const shouldCreateVisit = await getConfirm({
        title: "Client has a card on file",
        description: `Client already has a card on file attached: ${brand}, ${last4}, ${expMonth}/${expYear}. You can book an appointment now or send a new link to collect a card anyway`,
        confirmButtonText: "Book now",
        discardButtonText: "Copy link",
      });

      if (shouldCreateVisit) {
        await handleCreateVisit();
        return;
      }
    }

    try {
      const query = {
        serviceIds: services.join(","),
        providerId,
        startStr: zonedTimeToUtcIso(start),
        endStr: zonedTimeToUtcIso(end),
        dateQuery: zonedTimeToUtcIso(start),
        firstName,
        lastName,
        email,
        phone,
        isFds: "true",
      };

      const url = `/booking/${slug}?${new URLSearchParams(query).toString()}`;

      /**
       * @see https://wolfgangrittner.dev/how-to-use-clipboard-api-in-safari/
       */
      const shortUrl = new ClipboardItem({
        "text/plain": generateShortLink(url).then(
          (text) => new Blob([text], { type: "text/plain" })
        ),
      });
      if (!shortUrl) return;
      await navigator.clipboard.write([shortUrl]);
    } catch (e) {
      toast.error(
        hasDjangoMutationError(e)
          ? e.message
          : `There was a problem with copying the URL. Please try again.`
      );
      logError(e);
      return;
    }

    const shouldReturnToSchedule = await getConfirm({
      title: "Success!",
      description:
        "Credit Card link saved to clipboard. Return to schedule tab?",
      confirmButtonText: "Yes",
      discardButtonText: "No",
    });

    if (shouldReturnToSchedule) {
      closeDrawer?.();
      await push(`/${medspa}/visits`);
    }
  };

  return {
    createBookingWithPaymentLink,
    getClientsCreditCard,
    cancellationFeePolicyEnabled:
      medspaConfigurationData?.medspaByPk.configuration
        ?.cancellationFeePolicyEnabled,
  };
}
